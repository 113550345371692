import React from "react";
import "../pages/addreturns.css";
import SubmitBTNIcon from '../assets/sub-btn-icon.png';
import CanCelBtn from '../assets/cancel-claim-btn.png';
import DraftBTNIcon from '../assets/save-draft-icon.png';
import { useForm, useFieldArray } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ReturnMenu from "./returnsmenu";
import { useState, useEffect } from "react";
import { add_return_claim_api } from '../api/rma_add_return_api.js';
import GIFLOADER from '../assets/loader.gif';
import moment from "moment/moment.js";
import axios from 'axios';
import newlogout from '../assets/logoutblack.svg';
import backnew from "../assets/backnew.svg"
import { Link } from "react-router-dom";






function AddReturns() {
    const { register, handleSubmit, control, unregister, getValues, setError, clearErrors, reset, watch, setValue, resetField, setFocus, formState: { errors } } = useForm({
        mode: "onBlur",
    });
    const { fields: repair_list, append: appendRepair, remove: removeRepair, insert: insertRepair } = useFieldArray({
        control,
        name: 'parts',
    });

    let [parts, setRepairs] = useState([{
        invoice_no: '', invoice_no_line: '', part_no: '', part_description: '', return_qty: '', reason_code: '', replace_required: '', pickup_arrangements: '', comments: '', invoice_part_list: []
    }]);
    var shortDate;
    const navigate = useNavigate();
    const [activeRepairIndex, setActiveRepairIndex] = useState(0);
    var [IsAllFieldValid, SetIsAllFieldValid] = useState(true);
    var [showERRfields, SetshowERRfields] = useState(false);
    var [showpartvalid, setshowpartInvalid] = useState(false);
    const [show, setShow] = useState(false);
    const [part_list, setPartList] = useState([]);
    const [part_description, setPart_description] = useState([]);
    const [partquantity, setQuantity] = useState([]);
    var [enableloader, SetBtnDisable] = useState(true);
    var [suMSG, SetSuMsg] = useState('');
    var [suchidden, ShowSuc] = useState(false);
    var [qtyerror, setQtyerror] = useState(false);
    const [accno, setAccNO] = useState();
    const [selectpart, setselectpart] = useState();
    var [errhidden, ShowErr] = useState(false);
    var [erMSG, SetErMsg] = useState('');
    var [isSeValidMsg, setisSeiralValidMsg] = useState(false);
    var [partTabChange, SetpartTabChange] = useState(false);
    var [reason, setReason] = useState(false);
    var [invoicedate, setInvoicedate] = useState(false);
    const [partValid, setPartvalid] = useState(false);
    const [btnerr, setbtnerr] = useState(true);
    const [partnumber, setpartnumber] = useState()
    const [activeyear, setActiveYear] = useState();
    var [user_max_acc, Setuser_max_acc] = useState();

    var parts_invoice_num = "parts." + activeRepairIndex + ".invoice_no";
    const current_parts_invoice = watch(parts_invoice_num);

    var parts_num_list = "parts." + activeRepairIndex + ".part_no";
    const current_parts_num = watch(parts_num_list);

    var parts_qty_list = "parts." + activeRepairIndex + ".return_qty";
    const current_parts_qty = watch(parts_qty_list);

    var parts_descr = "parts." + activeRepairIndex + ".part_description";
    const current_parts_descr = watch(parts_descr);

    var parts_invo_line = "parts." + activeRepairIndex + ".invoice_no_line";
    const current_parts_involine = watch(parts_invo_line);

    var parts_reason_code = "parts." + activeRepairIndex + ".reason_code";
    const current_parts_reason_code = watch(parts_reason_code);

    var invoice_part_list = "parts." + activeRepairIndex + ".invoice_part_list";
    const current_invoice_part_list = watch(invoice_part_list);

    var return_Req_dataDraft

    function cancelpop(event) {
        event.preventDefault();
        localStorage.setItem('fromdetails', 'pagecount')
        setShow(!show)
    }
    function staysamepage() {
        setShow(!show)
    }
    function backtoclaims() {
        return navigate('/returns');
    }
    function cancelqty() {
        setQtyerror(false)
    }
    function cancelreson() {
        setReason(false)
    }
    function cancelinvodate() {
        setInvoicedate(false)
    }

    const numValidate = (e) => {
        e.target.value = e.target.value.replace(/^0+/, '')

        if (parseInt(e.target.value) > parseInt(partquantity)) {
            setQtyerror(true)
        } else {
            setQtyerror(false)
        }
        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength)
        }
    }

    // useEffect(() => {
    //     // console.log(current_parts_qty)
    //     // console.log(partquantity)
    //     if (parseInt(current_parts_qty) > parseInt(partquantity)) {
    //         setQtyerror(true)
    //     } else {
    //         setQtyerror(false)
    //     }
    // }, [current_parts_qty])


    useEffect(() => {
        // console.log(current_parts_reason_code)
        if (current_parts_reason_code === '1A' && !partTabChange) {
            setReason(true)
        }
        const dateObj = new Date();
        const year = dateObj.getFullYear();
        setActiveYear(year)
    }, [current_parts_reason_code])


    useEffect(() => {
        // var response_data = profile_api().then(acc_no => {
        //     setAccNO(acc_no.data[0].account_no)
        //     localStorage.setItem('User_Acc', accno)
        // })
        Setuser_max_acc(localStorage.getItem('maxonAccount'));

        const request_data = {
            "invoice_no": current_parts_invoice,
            "user_id": localStorage.getItem('user_id')
        }
        console.log(request_data)
        const headers = {
            "AUTHORIZATION": process.env.REACT_APP_LAMBDA_AUTHORIZATION
        }
        if (!partTabChange && current_parts_invoice) {
            setPartList([]);
            // setValue(invoice_part_list, []);
            setValue(parts_qty_list, '')
            setValue(parts_num_list, '')
            setValue(parts_descr, '')
            setbtnerr(true)
            const delayApiCall = setTimeout(() => {
                axios.post(process.env.REACT_APP_RMA_INVOICE_VALIDATE_API, request_data, { headers }).then(response => {
                    console.log(response.data)
                    if (response) {
                        const past = moment().subtract(1, 'years');
                        const today = moment();
                        const invodate = moment(today).isAfter(response.data.invoice_date, 'year');
                        if (invodate == true) {
                            setInvoicedate(true)
                        } else {
                            setInvoicedate(false)
                        }
                        setbtnerr(false)
                        setValue(invoice_part_list, response.data.part_list);
                        ShowSuc(suchidden = true);
                        SetSuMsg(response.data.msg);
                        setselectpart('')
                        setValue(parts_num_list, '')
                        SetErMsg('');
                        ShowErr(errhidden = false);
                        setTimeout(() => {
                            ShowSuc(suchidden = false);
                        }, 2000);
                        setPartList(response.data.part_list)
                    }
                }).catch(err => {
                    console.log(err);
                    setPartList([])
                    setPart_description('')
                    setValue(parts_descr, '')
                    setQuantity('')
                    setselectpart('')
                    setValue(parts_invo_line, '')
                    ShowSuc(suchidden = false);
                    setValue(parts_qty_list, '')
                    setValue(parts_num_list, '')
                    SetErMsg(suMSG = err.response.data.msg);
                    ShowErr(errhidden = true);
                    setTimeout(() => {
                        ShowErr(errhidden = false);
                    }, 6000);
                });
            }, 1500);
            return () => clearTimeout(delayApiCall);
        } else {
            if (current_parts_invoice == '') {
                setselectpart('')
                setValue(parts_num_list, '')
                setPartList([])
            } else {
                if (current_invoice_part_list == '' || current_invoice_part_list == undefined || current_invoice_part_list == null) {
                    setPartList([])
                } else {
                    setPartList(current_invoice_part_list)
                }
                SetpartTabChange(false)
                setselectpart(current_parts_num)
                setValue(parts_num_list, current_parts_num)
            }
        }

    }, [current_parts_invoice])


    useEffect(() => {
        if (partTabChange) {
            setselectpart(current_parts_num);
            setValue(parts_num_list, current_parts_num)
            setPart_description(current_parts_descr)
            setValue(parts_descr, current_parts_descr);
            setValue(parts_qty_list, current_parts_qty)
            setQuantity(current_parts_qty)
            if (current_invoice_part_list == '' || current_invoice_part_list == undefined || current_invoice_part_list == null) {
                setPartList([])
            } else {
                setPartList(current_invoice_part_list)
            }
            SetpartTabChange(false)
            console.log(current_parts_descr)
            var part_desc = part_list.filter(part => part.InvcDtl_PartNum == current_parts_num)
            if ((current_parts_num == undefined || current_parts_num == '') || part_desc != undefined || part_desc.length > 0) {
                setPartvalid(false)
            } else {
                setPartvalid(true)
            }
        } else {
            console.log(current_parts_invoice)
            if (current_parts_num != undefined && current_parts_num != '' && current_parts_invoice != undefined && current_parts_invoice != '') {
                setPartvalid(true)
                var splitpart = current_parts_num.split(' / ');
                // console.log(splitpart[0])
                setselectpart(splitpart[0]);
                setValue(parts_num_list, splitpart[0]);
                var part_desc = part_list.filter(part => part.InvcDtl_PartNum == splitpart[0])
                if (part_desc != undefined && part_desc.length > 0) {
                    setPartvalid(false)
                    setshowpartInvalid(false)
                    setPart_description(part_desc[0].InvcDtl_LineDesc)
                    setValue(parts_descr, part_desc[0].InvcDtl_LineDesc)
                    setValue(parts_invo_line, part_desc[0].InvcDtl_InvoiceLine)
                    setQuantity(parseFloat(part_desc[0].InvcDtl_SellingShipQty).toFixed(2))
                    setValue(parts_qty_list, parseFloat(part_desc[0].InvcDtl_SellingShipQty).toFixed(2))


                    var fvalue = control._formValues.parts;
                    fvalue.forEach((partval, index) => {
                        console.log(partval)
                        if (partval.part_no != "" && partval.part_no != null && partval.part_no != undefined && partval.part_no != 'undefined') {
                            if (control._formState.errors.parts && control._formState.errors.parts[index] && control._formState.errors.parts[index].part_no && control._formState.errors.parts[index].part_no.message) {
                                control._formState.errors.parts[index].part_no.message = '';
                            }
                        }
                        if (partval.return_qty != '' && partval.return_qty != null && partval.return_qty != undefined && partval.return_qty != 'undefined') {
                            console.log('qty not empty')
                            console.log(control._formState)
                            if (control._formState.errors.parts) {
                                if (control._formState.errors.parts[index]?.return_qty && control._formState.errors.parts[index]?.return_qty.message) {
                                    control._formState.errors.parts[index].return_qty.message = '';
                                }
                            }
                        }
                    })
                } else {
                    setPart_description('')
                    setValue(parts_descr, '')
                    setQuantity('')
                    setValue(parts_qty_list, '')
                }
            } else {
                setselectpart('')
                setPart_description('')
                setValue(parts_descr, '')
                setQuantity('')
                setValue(parts_qty_list, '')
            }
        }

    }, [current_parts_invoice, current_parts_num]);

    const onSubmit = async (req_data, e) => {
        e.preventDefault();
        const curDate = moment(new Date()).format("MM-DD-YYYY");
        SetIsAllFieldValid(false);
        req_data.status = 1;
        req_data.maxon_account_no = user_max_acc;
        req_data.returnsdate = curDate;
        console.log(req_data)
        SetBtnDisable(enableloader = false);
        var response_data = await add_return_claim_api(req_data);
        console.log(response_data)
        if (response_data.success) {
            ShowSuc(!suchidden);
            SetSuMsg(response_data.msg)
            setTimeout(() => {
                ShowSuc(suchidden = true);
                SetBtnDisable(enableloader = true);
                return navigate("/returns");
            }, 3000);
        }
    }

    const ondraft = async (event) => {
        // event.preventDefault();
        SetIsAllFieldValid(false);
        const curDDate = moment(new Date()).format("MM-DD-YYYY");
        return_Req_dataDraft = getValues();
        // console.log(return_Req_dataDraft)
        // return
        return_Req_dataDraft.returnsdate = curDDate;
        return_Req_dataDraft.maxon_account_no = localStorage.getItem('maxonAccount');
        // if (return_Req_dataDraft.parts[0].invoice_no === '') {
        //     setisSeiralValidMsg(true)
        //     setTimeout(() => {
        //         setisSeiralValidMsg(false)
        //     }, 4000);
        //     setFocus('part_no');
        // } else {
        SetBtnDisable(enableloader = false);
        return_Req_dataDraft.status = 0;
        return_Req_dataDraft.maxon_account_no = user_max_acc;
        var response_data = await add_return_claim_api(return_Req_dataDraft);
        console.log(response_data)
        if (response_data.success) {
            ShowSuc(!suchidden);
            SetSuMsg(response_data.msg);
            setTimeout(() => {
                ShowSuc(suchidden = true);
                SetBtnDisable(enableloader = true);
                return navigate("/returns");
            }, 3000);
        }
        // }
    }

    const OnError = (error) => {
        console.log(error)
        var petnum = '';
        var fvalue = control._formValues.parts;
        fvalue.forEach((partval, index) => {
            console.log(partval)
            if (partval.part_no != "" && partval.part_no != null && partval.part_no != undefined && partval.part_no != 'undefined') {
                if (control._formState.errors.parts && control._formState.errors.parts[index] && control._formState.errors.parts[index].part_no && control._formState.errors.parts[index].part_no.message) {
                    control._formState.errors.parts[index].part_no.message = '';
                }
            }
            if (partval.part_description == null || partval.part_description == '') {
                if (petnum != '') {
                    petnum += ','
                }
                petnum += ' ' + (index + 1)
                setshowpartInvalid(true)
                SetshowERRfields(false);
                setTimeout(() => {
                    setshowpartInvalid(false)
                }, 5000);
            } else {
                SetshowERRfields(true);
            }

        })
        setpartnumber(petnum)
        setTimeout(() => {
            SetshowERRfields(false);
        }, 5000);
    }

    const activeRIndex = (index, event) => {
        // console.log(current_parts_invoice)
        event.preventDefault();
        if (index == 0) {
            var repairIdx = index;
        } else {
            var repairIdx = index - 1;
        }
        setActiveRepairIndex(index);
        SetpartTabChange(true)
        var getVal = getValues();
        console.log(getVal)
        if (getVal.parts[index].invoice_no == '' && getVal.parts[index].part_no == '') {
            var rep_comp_val = "parts." + index + ".part_description";
            setPart_description('')
            setValue(rep_comp_val, '');
            var part_no_val = "parts." + index + ".part_no";
            setQuantity('')
            setValue(part_no_val, '');
        }
    }

    const repairRemove = (index, event) => {
        event.preventDefault();
        parts.splice(index, 1);
        if (index == 0) {
            var repairIdx = index;
        } else {
            var repairIdx = index - 1;
        }
        setbtnerr(false)
        SetpartTabChange(true)
        setActiveRepairIndex(repairIdx);
    }

    const addRepair = () => {
        setbtnerr(true)
        setQuantity('')
        setPartList([])
        setPart_description('')
        // setPartvalid(false)
        setPartvalid(false)
        var partId = parts.length + '-0'
        parts.push({ invoice_no: '', invoice_no_line: '', part_no: '', part_description: '', return_qty: '', reason_code: '', replace_required: '', pickup_arrangements: '', comments: '', invoice_part_list: [] })
        var len = parts.length - 1;
        setActiveRepairIndex(len);
        var invo_part_list = "parts." + len + ".invoice_part_list"
        setValue(invo_part_list, [])
    }
    return (
        <div className="add-returns-container">
            <ReturnMenu />
            <div className="container details_All ret_hr">
                <div hidden={enableloader} className='loader'>
                    <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                </div>
                {showERRfields && <small className='Errmsg Warrenty Popup'>** Please fill the all parts tab fields</small>
                }
                {suchidden && < div className='toaster suc returns_suc'>
                    <h2>{suMSG}</h2>
                </div>}
                {errhidden && <div className='toaster fail mdesc rtnerr' >
                    <h2>{erMSG}</h2>
                </div>}
                {/* {
                    isSeValidMsg && <small className='Errmsg Warrenty Popup'>Please Enter Invoice Number</small>
                } */}
                {
                    showpartvalid && <small className='Errmsg Warrenty Popup invopaart'>Enter Valid Invoice Number/Part Number for Part {partnumber} </small>
                }
                <div className=" ret_top">
                    <h3>Add RMA</h3>
                    <div className="warrtylog" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <div className="logImg"><img src={newlogout}></img></div>
                        <div className="warrntyp" >
                            <p>LOG OUT</p>
                        </div>
                    </div>
                </div>
                <hr />
                {
                    show && <div className="CanPop">
                        <div className="can_popTotal">
                            <div className='can_title'>
                                Confirmation
                            </div>
                            <hr />
                            <div className='cancel_content'>
                                <p>
                                    By cancelling, any data you have provided will not be saved.
                                </p>
                            </div>
                            <hr />
                            <div className='cancel_btns'>
                                <button className='can_no' onClick={staysamepage}>Cancel</button>
                                <button className='can_yes' onClick={backtoclaims}>Ok</button>
                            </div>
                        </div>
                    </div>
                }
                {
                    reason && <div className="CanPop">
                        <div className="can_popTotal">
                            <div className='can_title'>
                                Confirmation
                            </div>
                            <hr />
                            <div className='cancel_content'>
                                <p>
                                    Restocking fees may apply.
                                </p>
                            </div>
                            <hr />
                            <div className='cancel_btns'>
                                <button className='can_yes' onClick={cancelreson}>Ok</button>
                            </div>
                        </div>
                    </div>
                }
                {
                    qtyerror && <div className="CanPop">
                        <div className="can_popTotal">
                            <div className='can_title'>
                                Confirmation
                            </div>
                            <hr />
                            <div className='cancel_content'>
                                <p>
                                    Quantity is greater than invoiced quantity. Restocking fees may apply.
                                </p>
                            </div>
                            <hr />
                            <div className='cancel_btns'>
                                <button className='can_yes' onClick={cancelqty}>Ok</button>
                            </div>
                        </div>
                    </div>
                }
                {
                    invoicedate && <div className="CanPop">
                        <div className="can_popTotal">
                            <div className='can_title'>
                                Confirmation
                            </div>
                            <hr />
                            <div className='cancel_content'>
                                <p>
                                    Invoice is over one year old restocking fees may apply.
                                </p>
                            </div>
                            <hr />
                            <div className='cancel_btns'>
                                <button className='can_yes' onClick={cancelinvodate}>Ok</button>
                            </div>
                        </div>
                    </div>
                }
                <div className="previous_icon row return_back">
                    <div className="col-md-2" onClick={cancelpop} >
                        <img src={backnew} />
                        <span> Back to RMA</span>
                    </div>
                </div>
                <div className="return_details">
                    <div className="card">
                        <div className="card-body">
                            <form>
                                <div className="basic_return rtncard">
                                    <div className="return_basic">
                                        <p>Product Information</p>
                                    </div>
                                    <div className="parts_section">
                                        <div className="repair-tabs rma_parts">
                                            {parts.map((repair, index) => (
                                                <React.Fragment key={index + 'sS'}>
                                                    <div className="repair-intabs tabsspace">
                                                        <button
                                                            className={index === activeRepairIndex ? 'active' : ''}
                                                            onClick={(event) => activeRIndex(index, event)}
                                                        >
                                                            Part {index + 1}
                                                        </button>
                                                        {parts.length > 1 && (
                                                            <button key={index + 'a'}
                                                                className='repair-tabss close active' onClick={(event) => { repairRemove(index, event); removeRepair(index) }}>
                                                                X
                                                            </button>)}
                                                    </div>
                                                </React.Fragment>
                                            ))}

                                            <button type="button" className='addrepairbtn rtnplus' onClick={addRepair}>
                                                +
                                            </button>
                                        </div>
                                        <div className="field_section">
                                            {parts.map((repair, index) => (
                                                <div key={index + 'g'} className={index === activeRepairIndex ? 'active' : 'inactive'}>
                                                    {(
                                                        <>
                                                            <div className="row section_gap">
                                                                <div className="col-md-6">
                                                                    <label>Maxon Invoice #*</label><br />
                                                                    <input type="text" className="form-control" placeholder="Enter the Invoice Number"  {...register(`parts.${index}.invoice_no`, {
                                                                        required: {
                                                                            value: IsAllFieldValid, message: 'Maxon Invoice is required'
                                                                        }
                                                                    }
                                                                    )} />
                                                                    {IsAllFieldValid && errors?.parts?.[index]?.invoice_no && <small className='errMsg'>{errors?.parts?.[index]?.invoice_no.message}</small>}
                                                                    {
                                                                        isSeValidMsg && <small className='invo_class'>Please Enter Invoice #</small>
                                                                    }
                                                                </div>
                                                                <div className="col-md-6 involine">
                                                                    <input type="text" className="form-control" placeholder="Enter the Invoice Number"  {...register(`parts.${index}.invoice_no_line`,
                                                                    )} />
                                                                </div>
                                                            </div>
                                                            <div className="row section_gap">
                                                                <div className="col-md-6">

                                                                    {/* <div>
                                                                        <label>Part Number*</label><br />
                                                                        <select defaultValue={selectpart} className="form-select" aria-label="Default select example" {...register(`parts.${index}.part_no`, {
                                                                            required: {
                                                                                value: IsAllFieldValid, message: 'Part Number is required'
                                                                            }
                                                                        })} >
                                                                            <option value=''>Select</option>
                                                                            {part_list && part_list.map((pnList) => {
                                                                                return (
                                                                                    <option key={pnList.InvcDtl_PartNum} value={pnList.InvcDtl_PartNum}>{pnList.InvcDtl_PartNum} / {pnList.InvcDtl_LineDesc} </option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                        {IsAllFieldValid && errors?.parts?.[index]?.part_no?.type === "required" &&
                                                                            <small className='errMsg'>{errors?.parts?.[index]?.part_no.message}</small>}
                                                                    </div> */}
                                                                    <label>Part Number*</label><br />
                                                                    <input type="text" list="partlists" autoComplete="off" className="form-control" placeholder="Enter the Part Number" {...register(`parts.${index}.part_no`, {
                                                                        required: {
                                                                            value: IsAllFieldValid, message: 'Part Number is required'
                                                                        }
                                                                    })} />
                                                                    <datalist id="partlists">
                                                                        {part_list && part_list.map((pnList) => (
                                                                            <option key={pnList.InvcDtl_PartNum} value={pnList.InvcDtl_PartNum + ' / ' + pnList.InvcDtl_LineDesc} />
                                                                        ))}
                                                                    </datalist>
                                                                    {partValid ? <small className='errMsg'>Enter valid Part Number <br /></small> : ''}
                                                                    {IsAllFieldValid && errors?.parts?.[index]?.part_no?.type === "required" &&
                                                                        <small className='errMsg'>{errors?.parts?.[index]?.part_no.message}</small>}
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Part Description</label><br />
                                                                    <textarea rows="2" disabled type="text" readOnly className="form-control" value={part_description} placeholder="Enter the Part Description"  {...register(`parts.${index}.part_description`, {
                                                                        required: {
                                                                            value: IsAllFieldValid
                                                                        }
                                                                    })} />
                                                                </div>
                                                            </div>
                                                            <div className="row section_gap">
                                                                <div className="col-md-6">
                                                                    <label>Quantity*</label><br />
                                                                    <input type="number" className="form-control" placeholder="Enter the Quantity" maxLength={10} onInput={numValidate}
                                                                        onWheel={(e) => e.target.blur()} defaultValue={partquantity}
                                                                        {...register(`parts.${index}.return_qty`, {
                                                                            required:
                                                                                { value: IsAllFieldValid, message: 'Quantity is required' }
                                                                        })} />
                                                                    {IsAllFieldValid && errors?.parts?.[index]?.return_qty?.type === "required" && (
                                                                        <small className='errMsg'>{errors?.parts?.[index]?.return_qty.message}</small>
                                                                    )}
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Reason Code*</label><br />
                                                                    <select className="form-select" aria-label="Default select example"{...register(`parts.${index}.reason_code`, {
                                                                        required:
                                                                            { value: IsAllFieldValid, message: 'Reason Code is required' }
                                                                    })}>
                                                                        <option value=''>Select</option>
                                                                        <option value="1A">Customer Error</option>
                                                                        <option value="5A">Maxon Error</option>
                                                                        <option value="1D">Overstock</option>
                                                                    </select>
                                                                    {IsAllFieldValid && errors?.parts?.[index]?.reason_code?.type === "required" && (
                                                                        <small className='errMsg'>{errors?.parts?.[index]?.reason_code.message}</small>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="row section_gap">
                                                                <div className="col-md-6">
                                                                    <label>Are replacements required?*</label><br />
                                                                    <select className="form-select" aria-label="Default select example"{...register(`parts.${index}.replace_required`, {
                                                                        required: IsAllFieldValid
                                                                    })}>
                                                                        <option value=''>Select</option>
                                                                        <option value="Yes">Yes</option>
                                                                        <option value="No">No</option>
                                                                    </select>
                                                                    {IsAllFieldValid && errors?.parts?.[index]?.replace_required?.type === "required" && (
                                                                        <small className='errMsg'>Replacements is required</small>
                                                                    )}
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Are pick up arrangements needed?*</label><br />
                                                                    <select className="form-select" aria-label="Default select example"{...register(`parts.${index}.pickup_arrangements`, {
                                                                        required:
                                                                            { value: IsAllFieldValid, message: 'Arrangements needed is required' }
                                                                    })}>
                                                                        <option value=''>Select</option>
                                                                        <option value="Yes">Yes</option>
                                                                        <option value="No">No</option>
                                                                    </select>
                                                                    {IsAllFieldValid && errors?.parts?.[index]?.pickup_arrangements?.type === "required" && (
                                                                        <small className='errMsg'>Arrangements is required</small>
                                                                    )}
                                                                </div>

                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label>Comments*</label><br />
                                                                    <textarea type="text" className="form-control" placeholder="Type here" {...register(`parts.${index}.comments`, {
                                                                        required: IsAllFieldValid,
                                                                    })} ></textarea>
                                                                    {IsAllFieldValid && errors?.parts?.[index]?.comments?.type === "required" && (
                                                                        <small className='errMsg'>Comments is required</small>
                                                                    )}
                                                                </div>
                                                                <div className="col-md-6 involine">
                                                                    <input type="text" className="form-control" placeholder="Enter the Invoice Number"  {...register(`parts.${index}.invoice_part_list`,
                                                                    )} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {btnerr && <p className="hidetext"> Access to draft and submit requires a valid invoice#,part number and other informations.</p>
                                }
                                <div className="return_btns">
                                    <button className="rtn_can" onClick={cancelpop}><img src={CanCelBtn} alt='cancel-icon' /> Cancel</button>
                                    <button className="rtn_draft" onClick={handleSubmit(ondraft, OnError)} disabled={btnerr}>
                                        <img src={DraftBTNIcon} alt='draft-icon'
                                        /> Save Draft </button>
                                    <button className="rtn_submit" onClick={handleSubmit(onSubmit, OnError)} disabled={btnerr}>
                                        <img src={SubmitBTNIcon} alt='submit-icon' /> Submit</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
                <div className=" claim_footer">
                    <div className="footer_copy">
                        <p>Copyright &copy; {activeyear} Maxon Lift Crop. All rights reserved</p>
                    </div>
                    <div className="home_content">
                        <div className="clmuser"  >
                            <a rel='noopener noreferrer' target="_blank" href="https://www.maxonlift.com/web-warranty-rma-tutorial"><span>User Tutorial</span></a>
                        </div>
                        <Link to={'/privacy-policy'}>
                            Policy Privacy</Link>
                        <Link to={'/terms-Conditions'}>
                            Terms & Conditions</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default AddReturns