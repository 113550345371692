import { Outlet, Link } from "react-router-dom";



const Footer = () => {
    const today = new Date();
    const year = today.getFullYear();
    return (
        <div className="bot-bar">

            <div className="container">
                <div className="bottom_bar">
                    <div className="bottom_bar_left">
                        <p>Copyright ©  {year} Maxon Lift Corp. All rights reserved.</p>
                    </div>
                    <div className="bottom_bar_right">
                        <div className="bottom_privacy" >
                            <p>
                                <Link to="/privacy-policy">
                                    Privacy Policy
                                </Link>
                            </p>
                        </div>
                        <div className="trmsndcond">
                            <p>
                                <Link to="/terms-Conditions">
                                    Terms & Conditions
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;