import supabase from './supabaseconnect';

export async function claim_details_api(claim_id) {
    // console.log(claim_id)
    var { error } = await supabase.auth.refreshSession();

    var { data, error } = await supabase.from('claim').select('*').eq('id', claim_id);
    if (error) {
        var resepnse_data = { "success": false, "msg": error.message }
        return (resepnse_data);
    } else {
        var claim_details = data;

        var { data, error } = await supabase.from('user').select('*').eq('user_id', claim_details[0].user_id).eq('status','1');

        if (error) {
            var resepnse_data = { "success": false, "msg": error.message }
            return (resepnse_data);
        } else {
            if(data.length > 0){
                claim_details[0].created_by = data[0].first_name + ' ' + data[0].last_name
                claim_details[0].email_address = data[0].email_address
            }else{
                claim_details[0].created_by = ''
                claim_details[0].email_address = ''
            }
            return get_upload_file(claim_id, claim_details);
        }
    }
}

export async function get_upload_file(claim_id, claim_details) {
    const { data: signedUrl, error } = await supabase.storage.from('maxoninvoices').createSignedUrl(claim_id, 6000);

    if(signedUrl){
        claim_details[0].invoice_file = signedUrl;
    }else{
        claim_details[0].invoice_file = null;
    }

    return repair_list(claim_id, claim_details);
}

export async function repair_list(claim_id, claim_details) {
    var { data, error } = await supabase.from('repair_details').select('*').eq('claim_id', claim_id);
    if (error) {
        var resepnse_data = { "success": false, "msg": error.message }
        return (resepnse_data);
    } else {
        var repair_list = data;
        return parts_list(claim_id, claim_details, repair_list);
    }
}

export async function parts_list(claim_id, claim_details, repair_list) {
    var { data, error } = await supabase.from('parts').select('*').eq('claim_id', claim_id);
    if (error) {
        var resepnse_data = { "success": false, "msg": error.message }
        return (resepnse_data);
    } else {
        var final_claim_details = [];
        if (data.length > 0) {
            var parts_list_arr = [];
            repair_list.forEach((repair,index) => {
                var part_count = 0;
                data.forEach(parts => {
                    if (repair.id == parts.repair_id) {
                        parts.repair_no = index + 1;
                        part_count = part_count + 1;
                        parts.part_count = part_count;
                        parts_list_arr.push(parts);
                    }
                });
                // repair.parts_list = parts_list_arr;
            });
            claim_details[0].repair_list = repair_list
            claim_details[0].parts_list = parts_list_arr
            final_claim_details = claim_details;
        } else {
            claim_details[0].repair_list = repair_list
            claim_details[0].parts_list = []
            final_claim_details = claim_details;
        }

        // console.log(JSON.stringify(final_claim_details));
        var resepnse_data = { "success": true, "msg": "Calim Details Successfully", "data": final_claim_details }
        return (resepnse_data);
    }
}
