import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import GIFLOADER from '../assets/loader.gif';
import { useNavigate } from 'react-router-dom';
import "../pages/profile.css";
import LEFTMENU from "./leftMenu.js";
import { profile_api, changepassword_api } from "../api/profile_api.js";
import moment from "moment";
import pass_icon from "../assets/pass_lock.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { logout_api } from '../api/logout_api.js';
import view_png from "../assets/view.png"
import { Link } from "react-router-dom";
import newlogout from '../assets/logoutblack.svg';


const eye = <FontAwesomeIcon icon={faEye} />;



function Profile() {


    var [enableloader, SetBtnDisable] = useState(false);
    const [userData, SetuserData] = useState([])
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    var [suchidden, ShowSuc] = useState(true);
    var [errhidden, ShowErr] = useState(true);
    const [passwordShown, setPasswordShown] = useState(false);
    const [cnfpasswordShown, setCnfPasswordShown] = useState(false);
    var [suMSG, SetSuMsg] = useState('');
    var [erMSG, SetErMsg] = useState('');
    var [validatePassword, SetValidatePassword] = useState(true)
    var [creaton, setCreatOn] = useState();
    const [activeyear, setActiveYear] = useState();

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues, watch, reset,
    } = useForm();
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const toggleCnfPasswordVisiblity = () => {
        setCnfPasswordShown(cnfpasswordShown ? false : true);
    };
    useEffect(() => {
        SetValidatePassword(true);
        // window.history.forward();
        // SetBtnDisable(false)
        var response_data = profile_api().then(res => {
            console.log(res)
            if (res.success) {
                SetuserData(res.data[0]);
                setCreatOn(moment(res.data[0].created_at).format("MM-DD-YYYY"))
                SetBtnDisable(true)
                const dateObj = new Date();
                const year = dateObj.getFullYear();
                setActiveYear(year)
            }
        })
    }, []);

    const handleKeyDown = event => {
        if (event.keyCode === 32) {
            event.preventDefault();
        }
    };

    function closeChangebtn() {
        // return navigate("claims");
        // first_password == ''
        reset()
        SetValidatePassword(false)
        return setShow(!show);
    }
    const onSubmit = async (req_data, event) => {
        event.preventDefault();
        console.log(req_data);
        SetBtnDisable(enableloader = false);
        var response_data = await changepassword_api(req_data.first_password);
        console.log(response_data);

        if (response_data.success) {
            SetSuMsg(suMSG = response_data.msg);
            ShowSuc(!suchidden);
            // setTimeout(() => {
            //     ShowSuc(suchidden = true);
            //     SetBtnDisable(enableloader = true)
            //     setShow(!show);
            //     // var response_data = logout_api();
            // }, 3000);
            setTimeout(() => {
                ShowSuc(!suchidden);
                SetBtnDisable(enableloader = false);
                var response_data = logout_api();
                return navigate("/");

            }, 3000);
            // event.target.reset();
            // SetBtnDisable(enableloader = true);
        } else {
            SetBtnDisable(enableloader = true);
            SetErMsg(suMSG = response_data.msg);
            ShowErr(!errhidden);
            setTimeout(() => {
                ShowErr(errhidden = true);
                navigate("/");
            }, 3000);

        }
    }

    return (
        <div className='sideMenu '>
            <LEFTMENU />
            {/* <div className="menuall">
                <div className="lmenu">
                    <LEFTMENU />
                </div>
                <div className="tmenu">
                    <Topmenu />
                </div>
            </div> */}
            <div hidden={enableloader} className='loader'>
                <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
            </div>
            <div className='prof_toaster suc' hidden={suchidden}>
                <h2>{suMSG}</h2>
            </div>
            <div className='toaster fail' hidden={errhidden}>
                <h2>{erMSG}</h2>
            </div>
            <div className='container details_All'>
                <div className="claim_top_bar">
                    <div className=" user_topbar">
                        <h3>Profile</h3>
                        <div className="warrtylog" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            <div className="logImg"><img src={newlogout}></img></div>
                            <div className="warrntyp" >
                            <p>LOG OUT</p>
                            </div>
                            {/* <div className="control_search">
                                <form className="search-area">
                                    <img src={search_icon} alt="search-icon" />
                                    <input type="text"
                                        placeholder="Search..." onChange={(e) => searchResults(e.target.value)} />
                                </form>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="prof_card_total pt-5">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col prof_data">
                                    <label>First Name</label> <br />
                                    <p>{userData['first_name']}</p>
                                </div>
                                <div className="col prof_data">
                                    <label>Last Name</label> <br />
                                    <p>{userData['last_name']}</p>
                                </div>
                                <div className="col prof_data">
                                    <label>Company Name</label> <br />
                                    <p>{userData['company_name']}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col prof_data">
                                    <label>Email</label> <br />
                                    <p>{userData['email_address']}</p>
                                </div>
                                <div className="col prof_data">
                                    <label>Account Number</label> <br />
                                    <p>{userData['account_no']}</p>
                                </div>
                                <div className="col prof_data">
                                    <label>Created On</label> <br />
                                    {/* <p>{moment(userData.created_at).format("MM-DD-YYYY")}</p> */}
                                    <p>{creaton}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col prof_data">
                                    <button onClick={() => setShow(!show)}>Change Password</button>
                                </div>
                                {/* <div className="col prof_data">
                                    <label>Account Number</label> <br />
                                    <p>{userData['account_no']}</p>
                                </div>
                                <div className="col prof_data">
                                    <label>Created At</label> <br />
                                    {moment(userData.created_at).format("DD-MM-YYYY")}
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>
                {/* <div className="dummy">
                    <img src={user_icon} alt="User_icon"></img>
                    <input placeholder="Username" />
                </div> */}
                <div className="filter_popTotal">
                    {
                        show && <div className="deacTotal">
                            <div className="deactivateContent">
                                <form className='reset form-area cngpass p-5' onSubmit={handleSubmit(onSubmit)}>
                                    {/* <div hidden={enableloader} className='loader'>
                                        <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                                    </div> */}
                                    <h5 className="text-center">Change Password</h5>
                                    <div className="create_passsword">
                                        <img src={pass_icon} alt="password_icon" />
                                        <input type={passwordShown ? "text" : "password"} placeholder="New Password" id="first_password" onKeyDown={handleKeyDown}
                                            {...register("first_password", {
                                                required: validatePassword,
                                                minLength: 8
                                            })} />
                                        {/* <i onClick={togglePasswordVisiblity}>{eye}</i> */}
                                        <img className="eeyeee" src={view_png} alt="password_icon" onClick={togglePasswordVisiblity} />
                                        <br />
                                        {validatePassword && errors.first_password?.type === 'required' && (
                                            <small className='errMsg'>Password is required</small>
                                        )}
                                        {validatePassword && errors?.first_password?.type === "minLength" && (
                                            <small className='errMsg'>Password cannot less than 8 characters</small>
                                        )}
                                    </div>
                                    <div className="create_passsword">
                                        <img src={pass_icon} alt="password_icon" />
                                        <input type={cnfpasswordShown ? "text" : "password"} placeholder="Confirm Password" id="confirm_password" onKeyDown={handleKeyDown}
                                            {...register("confirm_password", {
                                                required: validatePassword,
                                                validate: (val) => {
                                                    if (watch('first_password') != val) {
                                                        return "Your passwords do no match";
                                                    }
                                                },
                                            })} />
                                        {/* <i onClick={toggleCnfPasswordVisiblity}>{eye}</i> */}
                                        <img className="eeyeee" src={view_png} alt="password_icon" onClick={toggleCnfPasswordVisiblity} />
                                        <br />
                                        {validatePassword && errors.confirm_password?.type === 'required' && (
                                            <small className='errMsg'>Password is required</small>
                                        )}

                                        {validatePassword && errors.confirm_password?.type === 'validate' && (
                                            <small className='errMsg'>Your passwords do no match</small>
                                        )}
                                    </div>
                                    <div className="craft_serch btn-area changePass_btns">
                                        <button className="changeCan" onClick={closeChangebtn}>Cancel</button>
                                        <button className='change_sub'>
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                </div>
                <div className="home_footer claim_footer">
                    <div className="footer_copy">
                        <p>Copyright &copy; {activeyear} Maxon Lift Crop. All rights reserved</p>
                    </div>
                    <div className="home_content">
                        <div className="clmuser" >
                            <a rel='noopener noreferrer' target="_blank" href="https://www.maxonlift.com/web-warranty-rma-tutorial"><span>User Tutorial</span></a>
                        </div>
                        <Link to={'/privacy-policy'}>
                            Policy Privacy</Link>
                        <Link to={'/terms-Conditions'}>
                            Terms & Conditions</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default Profile