import supabase from './supabaseconnect';

async function forget_password_api(email_id,pwd_set) {
    let { data, error } =await supabase.from('user').select('*').eq('email_address',email_id).order('created_at', { ascending: false });

    if(error){
        var resepnse_data = {"success":false,"msg":error.message}
        return(resepnse_data);
    }else{
        console.log(data);
        if(data.length > 0){
            if(data[0].status == 1){
                let { data1, error1 } = await supabase.auth.resetPasswordForEmail(email_id)
                if(error){
                    var resepnse_data = {"success":false,"msg":error.message}
                    return(resepnse_data);
                }else{
                    let { data2, error } =await supabase.from('user').update({ pwd_set: pwd_set }).eq('email_address',email_id);

                    if(error){
                        var resepnse_data = {"success":false,"msg":error.message}
                        return(resepnse_data);
                    }else{
                        var resepnse_data = {"success":true,"msg":"Password Requested Successfully"}
                        return(resepnse_data);
                    }
                }
            }else if(data[0].status == 0){
                var resepnse_data = {"success":false,"msg":"Account not yet approved"}
                return(resepnse_data);
            }else if(data[0].status == 2){
                var resepnse_data = {"success":false,"msg":"Your account has been rejected"}
                return(resepnse_data);
            }else if(data[0].status == 3){
                var resepnse_data = {"success":false,"msg":"Your account has been removed"}
                return(resepnse_data);
            }
        }else{
            var resepnse_data = {"success":false,"msg":"User Not Exist."}
            return(resepnse_data);
        }
    }
}

export default forget_password_api;