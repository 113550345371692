import React from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import LoginIcon from '../assets/login.png'
import RegisterIcon from '../assets/register.png'
import NewUserIcon from '../assets/question.png';
import pdf from "../assets/maxontutorial.pdf";

const Menu = () => {
  const location = useLocation();
  const { hash, pathname, search } = location;

  function onResumeClick() {
    window.open(pdf);
  }

  return (
    <>
      <nav className="p-2">
        <ul>
          <li className="nut">
            {/* <Link to="localhost:3000/src/assets/Warranty-tutorial.pdf"><img src={NewUserIcon} alt="New User Icon" />
              <span>New User Tutorial</span>
            </Link> */}
            <div className="tutPdf" >
              <img src={NewUserIcon} alt="New User Icon" />
              <a rel='noopener noreferrer' target="_blank" href="https://www.maxonlift.com/web-warranty-rma-tutorial"> <span>USER TUTORIAL</span></a>
            </div>
          </li>
        </ul>
      </nav>

      <Outlet />
      {/* Pathname: <b>{pathname}</b><br />
      Search params: <b>{search}</b><br />
      Hash: <b>{hash}</b> */}
    </>
  )
};

export default Menu;