import React from "react";
import "../pages/usersList.css";
import LEFTMENU from "./leftMenu";
import search_icon from '../assets/search_icon.png'
import filter_btn from "../assets/filter.png"
import supabase from "../api/supabaseconnect.js";
import user_list_api from "../api/user_list_api.js";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { user_approval_api, user_reject_api, user_remove_api } from "../api/user_approval_api.js";
import GIFLOADER from '../assets/loader.gif';
import { useForm } from 'react-hook-form';
import approveuser from "../assets/approveuser.svg";
import rejectuser from "../assets/rejectuser.svg";
import edituser from '../assets/edituser.svg';

function UsersList() {

    var [suchidden, ShowSuc] = useState(true);
    var [errhidden, ShowErr] = useState(true);
    var [btndis, SetBtnDisable] = useState(false);

    var [suMSG, SetSuMsg] = useState('');
    var [erMSG, SetErMsg] = useState('');
    const [users, SetUsers] = useState([]);
    const [session, setSession] = useState(null)
    const [searchValue, setSearchValue] = useState("")
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    // const recordsPerPage = 5;
    const [recordsPerPage, setrecordsPerPage] = useState(5);
    const [items_per_page, setItems_per_page] = useState(0);
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = users.slice(firstIndex, lastIndex);
    const npage = Math.ceil(users.length / recordsPerPage);
    const pnumbers = [...Array(npage + 1).keys()].slice(1);
    const [order, setOrder] = useState("ASC");
    const [show, setShow] = useState(false);
    const [toggle, SetToggle] = useState(1);
    const [popshow, setPopShow] = useState(false);
    const [filterStatus, SetfilterStatus] = useState([{}])

    const [userid, setUserId] = useState();
    const { register, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm();

    const onError = (errors) => {
        console.log(errors)
    }
    const filterSubmit = (req_data) => {
        document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
        localStorage.setItem('userlist_page_start', 0)
        localStorage.setItem('userlist_curr_page', 1)
        setCurrentPage(1)
        console.log(req_data)
        SetfilterStatus(req_data)
        setShow(!show);
        SetBtnDisable(false);
        var response_data = user_list_api(req_data, '', '', '').then(res => {
            SetUsers(res.data);
            SetBtnDisable(true);
            localStorage.setItem('userlstfilterdata', JSON.stringify(req_data))
        })
    }

    function ClearFilter() {
        reset();
        document.getElementById("divsection").style.backgroundColor = "white";
        localStorage.removeItem('userlstfilterdata');
        localStorage.setItem('userlist_page_start', 0)
        localStorage.setItem('userlist_curr_page', 1)
        setShow(!show)
        SetfilterStatus({});
        SetBtnDisable(false);
        setCurrentPage(1)
        setItems_per_page(0)
        var clear = user_list_api('', '', '', '').then(res => {
            SetUsers(res.data);
            SetBtnDisable(true);
        })

    }
    useEffect(() => {
        window.scrollTo(0, 0);
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        })
        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })
        var countfilteritmadmin = JSON.parse(localStorage.getItem('userlstfilterdata'));
        console.log(countfilteritmadmin)
        if (countfilteritmadmin != undefined && countfilteritmadmin != '' && countfilteritmadmin != null) {
            SetfilterStatus(countfilteritmadmin)
            // setChecked(!checked)
        } else {
            countfilteritmadmin = ''
        }
        if (countfilteritmadmin && countfilteritmadmin.approved === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('approved', countfilteritmadmin.approved === true)
        }
        if (countfilteritmadmin && countfilteritmadmin.not_approved === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('not_approved', countfilteritmadmin.not_approved === true)
        }
        if (countfilteritmadmin && countfilteritmadmin.rejected === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('rejected', countfilteritmadmin.rejected === true)
        }
        var response_data = user_list_api(countfilteritmadmin, '', '', '').then(res => {
            console.log(res);
            SetUsers(res.data);
            SetBtnDisable(true)
            var crtpage = localStorage.getItem('fromdetails')
            var countperpage = localStorage.getItem('currentpage');
            if (crtpage == 'pagecount' && countperpage != undefined && countperpage != '') {
                // setrecordsPerPage([])
                setrecordsPerPage(countperpage);
            }
            var claim_page_start = localStorage.getItem('userlist_page_start');
            var claim_curr_page = localStorage.getItem('userlist_curr_page');

            if (claim_page_start != undefined && claim_page_start != '' && claim_curr_page != undefined && claim_curr_page != '') {
                setItems_per_page(parseInt(claim_page_start));
                setCurrentPage(parseInt(claim_curr_page));
            }
        })
        return () => subscription.unsubscribe()
    }, [searchValue === ""]);

    function updateToggle(id) {
        SetToggle(id);
    }
    // Sortingggg    
    const sorting = (col) => {
        setCurrentPage(1)
        setItems_per_page(0)
        localStorage.setItem('userlist_page_start', 0)
        localStorage.setItem('userlist_curr_page', 1)
        if (order === 'ASC') {
            var response_data = user_list_api(filterStatus, col, order, '').then(res => {
                SetUsers(res.data)
            })
            setOrder('DSC');
        }
        if (order === 'DSC') {
            var response_data = user_list_api(filterStatus, col, order, '').then(res => {
                SetUsers(res.data)
            })
            setOrder('ASC');
        }
    }
    // PrePagination
    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
            var start_ind = (currentPage - 2) * recordsPerPage;
            setItems_per_page(start_ind)
            localStorage.setItem('userlist_page_start', start_ind)
            localStorage.setItem('userlist_curr_page', (currentPage-1))
        }
    }
    // currentPage
    function changeCurPage(id) {
        console.log(id)
        var start_ind = (id - 1) * recordsPerPage;
        setItems_per_page(start_ind)
        setCurrentPage(id)
    }
    // nextPage
    // var items_per_page
    function nextPage() {
        if (currentPage !== npage) {
            var start_ind = currentPage * recordsPerPage;
            setItems_per_page(start_ind)
            setCurrentPage(currentPage + 1)
            localStorage.setItem('userlist_page_start', start_ind)
            localStorage.setItem('userlist_curr_page', (currentPage+1))
        }
    }
    function pageChange(evt) {
        setCurrentPage(1)
        setItems_per_page(0)
        setrecordsPerPage(evt.target.value);
        localStorage.setItem('currentpage', evt.target.value)
        localStorage.setItem('userlist_page_start', 0)
        localStorage.setItem('userlist_curr_page', 1)
    }
    var search_res = watch('user_search')
    useEffect(() => {
        setCurrentPage(1)
        setItems_per_page(0)
        // console.log(result.target.value);
        const delayApiCall = setTimeout(() => {
            var response_data = user_list_api(filterStatus, '', '', search_res).then(res => {
                SetUsers(res.data)
            })
        }, 1000);
        return () => clearTimeout(delayApiCall);
    }, [search_res])
    function approveid(evt) {
        // console.log(evt.target.id)
        localStorage.setItem('approveId', evt.target.id);
    }

    // USER APPROVE----
    async function approveUser() {
        SetBtnDisable(false);
        var userId = localStorage.getItem('approveId');
        // console.log(userId)
        var approvedUser = await user_approval_api(userId);
        if (approvedUser.success) {
            SetSuMsg(suMSG = approvedUser.msg);
            ShowSuc(!suchidden);

            var response_data = user_list_api('', '', '', '').then(res => {
                SetUsers(res.data);
            })
            SetBtnDisable(true);
            setTimeout(() => {
                ShowSuc(suchidden = true);

            }, 3000);
        } else {
            SetErMsg(suMSG = approvedUser.msg);
            ShowErr(!errhidden);
            SetBtnDisable(true);
            setTimeout(() => {
                ShowErr(errhidden = true);
            }, 3000);
        }
    } const isEmpty = users.length === 0;

    function userdetails(evt) {
        console.log(evt)
        return navigate(`/userdetails/${evt}`);
    }


    // USER REJECT
    function rejectid(evt) {
        // console.log(evt.target.id)
        localStorage.setItem('rejectId', evt.target.id);
    }
    //USER DELETEEE
    function deleteUser(evt) {
        setUserId(evt)
        setPopShow(!popshow)
    }

    function staysamepage() {
        setPopShow(!popshow)
    }
    function deleteClaim() {
        setPopShow(!popshow)
        SetBtnDisable(false);
        var response_data = user_remove_api(userid).then(res => {
            SetSuMsg(res.msg)
            ShowSuc(false)
            setCurrentPage(1)
            setItems_per_page(0)
            setTimeout(() => {
                ShowSuc(true)
                SetSuMsg('')
                SetBtnDisable(true);
            }, 3000);
            var response_data = user_list_api('', '', '', '').then(res => {
                SetUsers(res.data);
            })
        })

    }
    async function rejectUser() {
        SetBtnDisable(false);
        var userId = localStorage.getItem('rejectId')
        var approvedUser = await user_reject_api(userId);
        if (approvedUser.success) {
            SetSuMsg(suMSG = approvedUser.msg);
            ShowSuc(!suchidden);
            SetBtnDisable(true);
            var response_data = user_list_api('', '', '', '').then(res => {
                SetUsers(res.data);
            })
            setTimeout(() => {
                ShowSuc(suchidden = true);

            }, 3000);
        } else {
            SetErMsg(suMSG = approvedUser.msg);
            ShowErr(!errhidden);
            setTimeout(() => {
                ShowErr(errhidden = true);
            }, 3000);
        }
    }
    return (
        <div className="userlist_total">
            <div>
                <LEFTMENU />
            </div>
            <div className="container details_All">
                <div hidden={btndis} className='loader'>
                    <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                </div>
                <div className='toaster suc' hidden={suchidden}>
                    <h2>{suMSG}</h2>
                </div>
                <div className='toaster fail' hidden={errhidden}>
                    <h2>{erMSG}</h2>
                </div>
                <div className="claim_top_bar">

                    <div className="user_topbar">
                        <div className="">
                            <h3>
                                Users List</h3>
                        </div>
                        <div className="userSearch">
                            <div className="control_search">
                                <form className="search-area">
                                    <img src={search_icon} alt="search-icon" />
                                    <input type="text"
                                        placeholder="Search..."{...register('user_search')} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {
                        popshow && <div className="CanPop">
                            <div className="can_popTotal">
                                <div className='can_title'>
                                    Confirmation
                                </div>
                                <hr />
                                <div className='cancel_content'>
                                    <p>
                                        Are you sure you want to delete the user?
                                    </p>
                                </div>
                                <hr />

                                <div className='cancel_btns'>
                                    <button className='can_no' onClick={staysamepage}>No</button>
                                    <button className='can_yes' onClick={deleteClaim}>Yes</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="claim_top_btns user_top_bar">
                    <div className="control_search">
                        {/* <form className="search-area">
                            <img src={search_icon} alt="search-icon" />
                            <input type="text"
                                placeholder="Search..."{...register('user_search')} />
                        </form> */}
                    </div>
                    <div className="claimBtns">
                        <button className="filter_btnn" onClick={() => setShow(!show)} id="divsection">
                            <img src={filter_btn}></img> Filter</button>
                    </div>
                </div>
                <div className="filter_popTotal" >
                    {
                        show && <div className="claimPop user_filter">
                            <div className="claim-popTotal">
                                <div className="popSubclass">
                                    <div className="claim-pop_left">
                                        <ul>
                                            <li onClick={() => updateToggle(1)}>Status</li>
                                        </ul>
                                    </div>
                                    <div className="claim-pop_right">
                                        <form onSubmit={handleSubmit(filterSubmit, onError)}>
                                            <div className={toggle === 1 ? "show-content" : "content"}>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck" {...register("approved")} />
                                                    <label for="defaultCheck" className="form-check-label" >
                                                        Approved
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1"  {...register("not_approved")} />
                                                    <label htmlFor="defaultCheck1" className="form-check-label" >
                                                        Not Approved
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck2"  {...register("rejected")} />
                                                    <label htmlFor="defaultCheck2" className="form-check-label" >
                                                        Rejected
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="filter_btns">
                                                <button className="clr_btn" onClick={ClearFilter}>Clear</button>
                                                <button className="filCan" onClick={() => setShow(!show)}>Cancel </button>
                                                <button type="submit" className="addclaim-sub-btn">Apply </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                </div>
                <div className="users_table table-responsive">
                    {isEmpty ? (
                        <p className="display_text">No users to show</p>
                    ) : (
                        <table className="table  gdgdfg">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col" onClick={() => sorting('first_name')}>  <b>First Name</b> <i className="fa fa-fw fa-sort"></i></th>
                                    <th scope="col" onClick={() => sorting('last_name')}>  <b>Last Name</b> <i className="fa fa-fw fa-sort"></i></th>
                                    <th scope="col" onClick={() => sorting('account_no')}>  Maxon Acc No.<i className="fa fa-fw fa-sort"></i></th>
                                    <th scope="col" onClick={() => sorting('email_address')}>  <b>Email</b> <i className="fa fa-fw fa-sort"></i></th>
                                    <th scope="col" onClick={() => sorting('company_name')}>  <b>Company Name</b> <i className="fa fa-fw fa-sort"></i></th>
                                    <th scope="col" onClick={() => sorting('status_value')}>  <b>Status</b> <i className="fa fa-fw fa-sort"></i></th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {records?.map((res, index) =>
                                    <tr key={res.id}>
                                        <td>{(items_per_page + index + 1)}</td>
                                        <td className="user_name">{res.first_name}</td>
                                        <td className="user_name">{res.last_name}</td>
                                        <td className="account_col">{res.account_no}</td>
                                        <td className="email_col">{res.email_address}</td>
                                        <td>  {res.company_name}                                                </td>
                                        <td>

                                            {(() => {
                                                if (res.status_value === 'Approved') {
                                                    return (
                                                        <div className="appcolor">
                                                            {res.status_value}
                                                        </div>
                                                    )
                                                } else if (res.status_value === 'Not Approved') {
                                                    return (
                                                        <div className="notappcolor">
                                                            {res.status_value}
                                                        </div>
                                                    )
                                                } else if (res.status_value === 'Rejected') {
                                                    return (
                                                        <div className="rejcolor">
                                                            {res.status_value}
                                                        </div>
                                                    )
                                                }
                                            })()}

                                        </td>
                                        <td >
                                            {(() => {
                                                if (res.status === 1) {
                                                    return (
                                                        <div className="admin_icons">
                                                            <img title="Reject" data-bs-toggle="modal" onClick={(event) => rejectid(event)} data-bs-target="#rejectModal" id={res.id} src={rejectuser}></img>
                                                            <img src={edituser} onClick={() => userdetails(res.id)}></img>
                                                        </div>
                                                    )
                                                } else if (res.status === 0) {
                                                    return (
                                                        <div className="admin_icons">
                                                            <img title="Approve" src={approveuser}
                                                                data-bs-toggle="modal" data-bs-target="#approveModal" id={res.id} onClick={(event) => approveid(event)}></img>
                                                            <img title="Reject" data-bs-toggle="modal" onClick={(event) => rejectid(event)} data-bs-target="#rejectModal" id={res.id} src={rejectuser}></img>
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div className="admin_icons">
                                                            <img title="Approve" src={approveuser}
                                                                data-bs-toggle="modal" data-bs-target="#approveModal" id={res.id} onClick={(event) => approveid(event)}></img>
                                                            <img src={edituser} onClick={() => userdetails(res.id)}></img>
                                                        </div>
                                                    )
                                                }
                                            })()}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                    <div className="mob_card">
                        <div className="card_data">
                            {records?.map((res, index) =>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="Card_top">
                                            <div>
                                                {(() => {
                                                    if (res.status_value === 'Approved') {
                                                        return (
                                                            <div className="appcolor">
                                                                {res.status_value}
                                                            </div>
                                                        )
                                                    } else if (res.status_value === 'Not Approved') {
                                                        return (
                                                            <div className="notappcolor">
                                                                {res.status_value}
                                                            </div>
                                                        )
                                                    } else if (res.status_value === 'Rejected') {
                                                        return (
                                                            <div className="rejcolor">
                                                                {res.status_value}
                                                            </div>
                                                        )
                                                    }
                                                })()}
                                            </div>
                                            <div className="edit_icon_card">
                                                {(() => {
                                                    if (res.status === 1) {
                                                        return (
                                                            <div>
                                                                <img title="Reject" data-bs-toggle="modal" onClick={(event) => rejectid(event)} data-bs-target="#rejectModal" id={res.id} src={rejectuser}></img>
                                                                <img src={edituser} onClick={() => userdetails(res.id)}></img>
                                                            </div>
                                                        )
                                                    } else if (res.status === 0) {
                                                        return (
                                                            <div>
                                                                <img title="Approve" src={approveuser}
                                                                    data-bs-toggle="modal" data-bs-target="#approveModal" id={res.id} onClick={(event) => approveid(event)}></img>
                                                                <img title="Reject" data-bs-toggle="modal" onClick={(event) => rejectid(event)} data-bs-target="#rejectModal" id={res.id} src={rejectuser}></img>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div>
                                                                <img title="Approve" src={approveuser}
                                                                    data-bs-toggle="modal" data-bs-target="#approveModal" id={res.id} onClick={(event) => approveid(event)}></img>
                                                                <img src={edituser} onClick={() => userdetails(res.id)}></img>
                                                            </div>
                                                        )
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                        <div className="row card_top"  >
                                            <div className="col-sm">
                                                <div className="mob_data_lable">
                                                    <label>First Name </label>
                                                    {res.first_name}
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="mob_data_lable">
                                                    <label>Last Name</label>
                                                    {res.last_name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row card_top">
                                            <div className="col-sm">
                                                <div className="mob_data_lable">
                                                    <label>Company Name </label>
                                                    {res.company_name}
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="mob_data_lable">
                                                    <label>Maxon Acc No.</label>
                                                    {res.account_no}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row card_top">
                                            <div className="col-sm w-100">
                                                <div className="mob_data_lable">
                                                    <label>Email</label>
                                                    {res.email_address}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="claim_pagination userListPagi">
                        <div className="itemsPage custom-select usePagination">
                            <p>Items Per Page:</p>
                            <select value={recordsPerPage} className="form-select" onChange={(e) => pageChange(e)}>
                                <option >5</option>
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </div>
                        <div className="page_wid">
                            <span className="User_count">Users({users.length})</span>
                            <nav className="user_Ui">
                                <ul className="pagination ">
                                    <li className="page-item">
                                        <a className="page-link"
                                            onClick={prePage}>Prev</a>
                                    </li>
                                    {/* {
                                        pnumbers.map((n, i) => (
                                            <div className="card_pagination">
                                                <li className={`page-item ${currentPage === n ? 'active' : ""}`} key={i}>
                                                    <a className="page-link"
                                                        onClick={() => changeCurPage(n)}>{n}</a>
                                                </li>
                                            </div>
                                        ))

                                    } */}
                                    <li className="page-item">
                                        <a className="page-link"
                                            onClick={nextPage}>Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    {/* Approve User----- */}
                    <div className="modal fade" id="approveModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog approve_total">
                            <div className="modal-content">
                                <div className="modal-header approve">
                                    <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    Are you sure you want to Approve Dealer ?
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="approveNo" data-bs-dismiss="modal">No</button>
                                    <button type="button" className="approveYes" data-bs-dismiss="modal" onClick={approveUser}>Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Reject user ---*/}
                    <div className="modal fade" id="rejectModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog approve_total">
                            <div className="modal-content">
                                <div className="modal-header approve">
                                    <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    Are you sure you want to Reject Dealer ?
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="approveNo" data-bs-dismiss="modal">No</button>
                                    <button type="button" className="approveYes" data-bs-dismiss="modal" onClick={rejectUser}>Yes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}



export default UsersList;