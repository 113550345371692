import { Password } from '@mui/icons-material';
import supabase from './supabaseconnect';
import { createClient } from "@supabase/supabase-js";

const supabase2 = createClient(process.env.REACT_APP_API_URL, process.env.REACT_APP_API_KEY, { auth: { storageKey: "s1" } });

export async function user_password_update_api(user_id,user_password) {
    var { data, error } = await supabase.from('user').select('*').eq('id', user_id);
    if (error) {
        var resepnse_data = { "success": false, "msg": error.message }
        return (resepnse_data);
    } else {
        if (data.length == 0) {
            var resepnse_data = { "success": false, "msg": "User not exists" }
            return (resepnse_data);
        } else {
            var email_id = data[0].email_address;
            var old_user_id = data[0].user_id;

            var { data1, error } = await supabase.from('user_pasword_update_log').insert({"old_user_id":old_user_id,"email_address":email_id});

            if (error) {
                var resepnse_data = { "success": false, "msg": error.message }
                return (resepnse_data);
            } else {
                const adminSupabase = createClient(process.env.REACT_APP_API_URL, process.env.REACT_APP_SERVICE_KEY);

                var { data1, error } =await adminSupabase.auth.admin.deleteUser(old_user_id);

                return create_user(email_id, user_password, old_user_id);
            }
        }
    }
}

export async function create_user(email_id, user_password, old_user_id) {

    var { data, error } = await supabase2.auth.signUp({
        email: email_id,
        password: user_password
    })
    if (error) {
        var resepnse_data = { "success": false, "msg": error.message }
        return (resepnse_data);
    } else {
        var new_user_id = data.user.id;

        var { data1, error } = await supabase.from('user_pasword_update_log').update({ "new_user_id": new_user_id }).eq('old_user_id', old_user_id);

        var { data1, error } = await supabase.from('user').update({ "user_id": new_user_id }).eq('user_id', old_user_id);

        var { data1, error } = await supabase.from('claim').update({ "user_id": new_user_id }).eq('user_id', old_user_id);

        var { data1, error } = await supabase.from('repair').update({ "user_id": new_user_id }).eq('user_id', old_user_id);

        var { data1, error } = await supabase.from('parts').update({ "user_id": new_user_id }).eq('user_id', old_user_id);

        var { data1, error } = await supabase.from('rma_base_table').update({ "user_id": new_user_id }).eq('user_id', old_user_id);

        var { data1, error } = await supabase.from('rma_return_part_list').update({ "user_id": new_user_id }).eq('user_id', old_user_id);

        var resepnse_data = { "success": true, "msg": "Password Updated Successfully" }

        return (resepnse_data);
    }
}