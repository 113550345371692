import supabase from './supabaseconnect';

export async function rma_delete_api(req_data) {

    var { error } = await supabase.auth.refreshSession();

    var { data, error } = await supabase.from('rma_base_table').update({"status": '2' }).eq('id', req_data);
    if (error) {
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {
        var response_data = {"success":true,"msg":"Deleted Successfully"}
        return(response_data);
    }
}
