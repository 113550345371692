import supabase from './supabaseconnect';

export async function get_rma_details_api(rma_id) {

    var { error } = await supabase.auth.refreshSession();

    var { data, error } = await supabase.from('rma_return_part_list').select('*').eq('rma_id',rma_id).order('part_line_no');
    if (error) {
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {
        var rma_details = data;

        var { data, error } = await supabase.from('user').select('*').eq('user_id', rma_details[0].user_id).eq('status','1');

        if (error) {
            var resepnse_data = { "success": false, "msg": error.message }
            return (resepnse_data);
        } else {
            if(data.length > 0){
                var created_by = data[0].first_name + ' ' + data[0].last_name
                var email_address = data[0].email_address
            }else{
                var created_by = ''
                var email_address = ''
            }

            var { data, error } = await supabase.from('rma_base_table').select('*').eq('id', rma_id);

            if (error) {
                var resepnse_data = { "success": false, "msg": error.message }
                return (resepnse_data);
            } else {

                if(data.length > 0){
                    var maxon_Account_no = data[0].maxon_account_no
                    var rma_status = data[0].status_value
                    var return_date = data[0].return_date
                }else{
                    maxon_Account_no = ''
                    rma_status = ''
                    return_date = ''
                }

                var response_data = { "success": true, "msg": "RMA Details", "data": rma_details ,  "maxon_Account_no": maxon_Account_no , "created_by" : created_by , "return_date" : return_date, "rma_status" : rma_status , "email_address" : email_address }
                return (response_data);
            }
        }
    }
}
