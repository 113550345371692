import React from "react";
import { Link } from 'react-router-dom';
import "../pages/newtutorial.css"


const NewTutorial = () => {


    return (
        <div className="newtutorialtotal">
            <div className="container">
                <div className="claim_top_bar">
                    <div className="row user_topbar">
                        <div className="col-md-9">
                            <h3>
                                New User Tutorial
                            </h3>
                        </div>
                    </div>
                </div>
                <div className='head_home'>
                    <Link to="/"><p>Home</p></Link>
                    <span> New user tutorial</span>
                </div>
            <div>
                <h1>In progresss.........</h1>
            </div>

            </div>
        </div>
    )
}


export default NewTutorial