import React from "react";
import '../pages/leftMenu.css'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { logout_api } from '../api/logout_api.js';
import { useEffect, useState } from "react";
import supabase from '../api/supabaseconnect';
import { profile_api } from "../api/profile_api.js";
import newclaim from '../assets/newclaims.svg';
import newuser from '../assets/newuser.svg';
import newlogout from '../assets/newlogout.svg';
import maxlogosvg from '../assets/maxlogosvg.svg';
import home from '../assets/homesvg.svg';
import rmasvg from '../assets/rmasvg.svg';


function ReturnMenu() {


    const navigate = useNavigate();
    var [suchidden, ShowSuc] = useState(true);
    var [errhidden, ShowErr] = useState(true);
    var [suMSG, SetSuMsg] = useState('');
    var [erMSG, SetErMsg] = useState('');
    var [btndis, SetBtnDisable] = useState(true);
    const [user_name, setuserName] = useState("");
    const [pwd_set, setPwdSet] = useState(0);


    const logoutUser = async () => {
        var response_data = await logout_api();
        // console.log(response_data);
        navigate("/");
        SetBtnDisable(btndis = false);
        if (response_data.success) {
            SetSuMsg(suMSG = response_data.msg);
            ShowSuc(!suchidden);
            setTimeout(() => {
                localStorage.clear();
                ShowSuc(suchidden = true);

            }, 3000);
            SetBtnDisable(btndis = true);
        } else {
            SetErMsg(suMSG = response_data.msg);
            ShowErr(!errhidden);
            setTimeout(() => {
                ShowErr(errhidden = true);
            }, 3000);
            SetBtnDisable(btndis = true);
        }
    }
    const [session, setSession] = React.useState(null)

    useEffect(() => {
        // window.history.forward();
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        })
        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            // console.log(session);
            if (!session) {
                return navigate('/');
            }
            setSession(session)
        })
        var response_data = profile_api().then(res => {
            if (res.success) {
                var userName = res.data[0].first_name + ' ' + res.data[0].last_name;
                setuserName(userName);
                setPwdSet(res.data[0].pwd_set);
                // SetBtnDisable(true)
            }
            //  else {
            //     return navigate('/');
            // }
        })
        return () => subscription.unsubscribe()
    }, [])

    // if (!session) {
    //     console.log('no session')
    //     return navigate('/');
    // } else {
    return (
        < div className="leftBar_main" >
            <div className="nav_total">
                <div className="max_logo">
                    <img src={maxlogosvg}></img>
                </div>
                {/* <hr /> */}
                <div className="profile_data">
                    <Link to={"/userprofile"}>
                        <img src={newuser}></img>
                        <div className="user_data">
                            <p>{user_name}</p>
                        </div>
                    </Link>
                </div>
                <div className="navContent logOut_pop">
                    <Link to="/returns">
                        {/* <div className="navImg"> */}
                        <img src={rmasvg}></img>
                        {/* </div> */}
                        <div className="contentName">
                            <p className="nav-pills"> RMA</p>
                        </div>
                    </Link>
                </div>
                <div className="navContent logOut_pop">
                    <Link to="/home">
                        <img src={home}></img>
                        <div className="contentName">
                            <p className="nav-pills"> HOME</p>
                        </div>
                    </Link>
                </div>
                {/* <div className="logOut_pop" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <div className="logImg"><img src={newlogout}></img></div>
                        <div className="contentName" >
                            <p><a style={{ color: '#ffffff' }} >LOG OUT</a></p>
                        </div>
                    </div> */}
            </div>
            <div className="modal " id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title log_title" id="exampleModalLabel">Confirmation</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to logout?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary no_btn" data-bs-dismiss="modal">No</button>
                            <button type="button" className="btn logyesbtn" data-bs-dismiss="modal" onClick={logoutUser}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
// }


export default ReturnMenu;