import supabase from './supabaseconnect';

export async function admin_edit_claim_api(req_data) {
    // console.log(req_data)
    // console.log(req_data.claim_id)

    var { error } = await supabase.auth.refreshSession();

    var error_ind = 0;
    var error_message = '';
    var repair_no = '';

    if(req_data.status == 1){
        if(req_data.repairs.length > 0){
            req_data.repairs.forEach((repair,index) => {
                if(repair.misc_charge != '' && repair.misc_charge != undefined && repair.misc_charge != null && repair.misc_charge != 'undefined'){
                    if(repair.misc_description == '' || repair.misc_description == undefined || repair.misc_description == null || repair.misc_description == 'undefined'){
                        error_ind = 1;
                        if(repair_no != ''){
                            repair_no += ', '
                        }

                        repair_no += index + 1;
                    }
                }else{
                    repair.misc_description = '';
                }
            });
            error_message = 'Repair (' + repair_no + ') Misc Description Required'

        }
        req_data.status_value = 'Submitted';
    }else{
        req_data.status_value = 'Draft';
    }

    if(error_ind == 1){
        var response_data = {"success":false,"msg":error_message}
        return(response_data);
    }else{
        if(req_data.cycle_count == undefined || req_data.cycle_count == ''){
            req_data.cycle_count = ''
        }

        if(req_data.invoice_date == undefined || req_data.invoice_date == ''){
            req_data.invoice_date = null;
        }
        if(req_data.repair_date == undefined || req_data.repair_date == ''){
            req_data.repair_date = null;
        }
        if(req_data.purchase_date == undefined || req_data.purchase_date == ''){
            req_data.purchase_date = null;
        }
        if(req_data.claim_expiry_date == undefined || req_data.claim_expiry_date == ''){
            req_data.claim_expiry_date = null;
        }

        var { data, error } = await supabase.from('claim').select('*').eq('id', req_data.claim_id);

        if (error) {
            var response_data = { "success": false, "msg": error.message }
            return (response_data);
        } else {
            var user_id = data[0].user_id;

            var { data1, error } = await supabase.from('claim').update({ "company_name": req_data.company_name, "purchase_date": req_data.purchase_date, "claim_expiry_date": req_data.claim_expiry_date, "partNum": req_data.partNum, "serial_no": req_data.serial_no, "web_entry_date": req_data.web_entry_date, "invoice_no": req_data.invoice_no, "invoice_date": req_data.invoice_date, "repair_date": req_data.repair_date, "model_no": req_data.model_no, "cycle_count": req_data.cycle_count, "no_of_repairs": req_data.repairs_count, "no_of_parts": req_data.parts, "total_claim": req_data.total_claim, "status": req_data.status,"status_value":req_data.status_value }).eq('id', req_data.claim_id);
            if (error) {
                var response_data = { "success": false, "msg": error.message }
                return (response_data);
            } else {
                let { data1, error } = await supabase.from('repair').delete().eq('claim_id',req_data.claim_id)
                if (error) {
                    var response_data = { "success": false, "msg": error.message }
                    return (response_data);
                } else {
                    let { data1, error } = await supabase.from('parts').delete().eq('claim_id',req_data.claim_id)
                    if (error) {
                        var response_data = { "success": false, "msg": error.message }
                        return (response_data);
                    } else {
                        return add_repair(req_data,user_id);
                    }
                }
            }
        }
    }
}

export async function add_repair(req_data,user_id) {
    var repair_list1 = []
    var final_repair_list = []
    var claim_id = req_data.claim_id;
    var repair_list = req_data.repairs;
    var repair_order = 0;
    var total_misc_charge = 0.00;
    var total_repair_amount = 0.00;
    var total_parts_amount = 0.00;

    repair_list.forEach(repair => {
        repair.claim_id = claim_id;
        if(repair.category != '' || repair.gate_type != '' || repair.component != '' || repair.labour_hours != '' || repair.labour_rate != '' || repair.labour_total != '' ||  repair.misc_charge != '' ||  repair.misc_description != '' ||  repair.repair_Description != '' ||  repair.repair_parts_total != '' ){
            repair_order = repair_order + 1;
            repair_list1.push({ "claim_id": repair.claim_id, "category_id": repair.category, "gate_type_id": repair.gate_type, "component_id": repair.component, "labour_hours": repair.labour_hours, "labour_rate": repair.labour_rate, "labour_total": repair.labour_total, "misc_charge": repair.misc_charge, "misc_description": repair.misc_description, "repair_Description": repair.repair_Description, "repair_parts_total": repair.repair_parts_total, "repair_order": repair_order, "user_id": user_id });

            final_repair_list.push(repair);

            if(repair.misc_charge != '' && repair.misc_charge != undefined && repair.misc_charge != null && !isNaN(repair.misc_charge) && repair.misc_charge != 'undefined'){
                total_misc_charge = total_misc_charge + parseFloat(repair.misc_charge);
            }

            if(repair.labour_total != '' && repair.labour_total != undefined && repair.labour_total != null && !isNaN(repair.labour_total) && repair.labour_total != 'undefined'){
                total_repair_amount = total_repair_amount + parseFloat(repair.labour_total);
            }
        }
    });

    total_misc_charge = parseFloat(total_misc_charge).toFixed(2);
    total_repair_amount = parseFloat(total_repair_amount).toFixed(2);

    let { data, error } = await supabase.from('repair').insert(repair_list1).select('id');
    if (error) {
        // console.log(JSON.stringify(data));
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {
        var part_list1 = [];

        final_repair_list.forEach((repair, index) => {
            var repair_id = data[index].id;

            repair.parts.forEach((part, indexes) => {
                part.repair_id = repair_id;
                if(part.part_number != '' || part.parts_description != '' || part.quantity_claimed != '' || part.unit_price_claimed != '' || (part.partstotal != '' && part.partstotal != undefined && part.partstotal != null && part.partstotal != 'null' && !isNaN(part.partstotal) && part.partstotal != '0')){
                    if((part.part_number != '' && part.parts_description != '') || (part.part_number == '' && part.parts_description == '')){
                        part_list1.push({ "claim_id": claim_id, "repair_id": repair_id, "part_no": part.part_number, "parts_description": part.parts_description, "qty_claimed": part.quantity_claimed, "unit_price_claimed": part.unit_price_claimed, "total": part.partstotal, "user_id": user_id });

                        if(part.partstotal != '' && part.partstotal != undefined && part.partstotal != null && !isNaN(part.partstotal) && part.partstotal != 'undefined'){
                            total_parts_amount = total_parts_amount + parseFloat(part.partstotal);
                        }
                    }
                }
            });
        });

        total_parts_amount = parseFloat(total_parts_amount).toFixed(2);
        var total_claim_amount = parseFloat(total_repair_amount) + parseFloat(total_parts_amount) + parseFloat(total_misc_charge);

        if (part_list1.length > 0) {
            let { data, error } = await supabase.from('parts').insert(part_list1).select('id');
            if (error) {
                // console.log(JSON.stringify(data));
                var response_data = { "success": false, "msg": error.message }
                return (response_data);
            } else {
                var repair_count = repair_list1.length;
                var part_count = part_list1.length;
                var total_count = repair_count + part_count;

                let { data, error } = await supabase.from('claim').update({"no_of_repairs": repair_count, "no_of_parts": part_count, "total_claim": total_count, "total_misc_charge": total_misc_charge, "total_repair_amount": total_repair_amount, "total_parts_amount": total_parts_amount, "total_claim_amount": total_claim_amount}).eq('id', req_data.claim_id);
                if (error) {
                    var response_data = { "success": false, "msg": error.message }
                    return (response_data);
                } else {
                    uploadFile(claim_id,req_data);

                    if(req_data.status == 1){
                        let { data, error } = await supabase.from('epicor_sync_log').insert([{"claim_id":claim_id}]);
                    }

                    var response_data = {"success":true,"msg":"Updated Successfully"}
                    return(response_data);
                }
            }
        } else {
            var repair_count = repair_list1.length;
            var part_count = part_list1.length;
            var total_count = repair_count + part_count;

            let { data, error } = await supabase.from('claim').update({"no_of_repairs": repair_count, "no_of_parts": part_count, "total_claim": total_count, "total_misc_charge": total_misc_charge, "total_repair_amount": total_repair_amount, "total_parts_amount": total_parts_amount, "total_claim_amount": total_claim_amount}).eq('id', req_data.claim_id);
            if (error) {
                var response_data = { "success": false, "msg": error.message }
                return (response_data);
            } else {
                uploadFile(claim_id,req_data)

                if(req_data.status == 1){
                    let { data, error } = await supabase.from('epicor_sync_log').insert([{"claim_id":claim_id}]);
                }

                var response_data = {"success":true,"msg":"Updated Successfully"}
                return(response_data);
            }
        }
    }
}

async function uploadFile(claim_id,req_data){
    // await supabase.storage.from('bucket').remove(['object-key-1', 'object-key-2'])
    if(req_data.is_file_removed){
         var {data , error} = await supabase.storage.from('maxoninvoices').remove([claim_id]);
         if(error){
            console.log("File Delete error",error);
         }
         else{
            console.log("File Deleted Successfully")
            if(req_data.invoice_file != '' && req_data.invoice_file != undefined && req_data.invoice_file != null && req_data.invoice_file != 'undefined'){
                const { data, error } = await supabase.storage.from('maxoninvoices').upload(claim_id, req_data.invoice_file)
                if (error) {
                    console.log("File Upload error",error);
                } else {
                    console.log("Uploaded Successfully");
                }
            }
         }
    }else{
        if(req_data.invoice_file != '' && req_data.invoice_file != undefined && req_data.invoice_file != null && req_data.invoice_file != 'undefined'){
            const { data, error } = await supabase.storage.from('maxoninvoices').upload(claim_id, req_data.invoice_file)
            if (error) {
                console.log("File Upload error",error);
            } else {
                console.log("Uploaded Successfully");
            }
        }
    }
}