import React from "react";
import '../pages/login.css';
import maxon_logo from '../assets/max_logo.png';
import user_icon from '../assets/user_icon.png';
import pass_icon from "../assets/pass_lock.png";
import log_into from "../assets/loginto.png";
import Menu from "./Menu";
import { useForm } from 'react-hook-form';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
// import API from '../api';
import { useEffect, useState } from "react";
import GIFLOADER from '../assets/loader.gif'
// import { AES, enc } from 'crypto-js';
import Footer from "./Footer";
import NewUserIcon from '../assets/question.png';
import login_api from '../api/login_api.js';
import MobileMenu from "./MobileMenu.js";
import supabase from "../api/supabaseconnect.js";
import { useNavigate } from 'react-router-dom';
import Nopage from "./Nopage.js";
import { Link } from "react-router-dom";
import { eyeslash } from "react-icons"
import view_png from "../assets/view.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const eye = <FontAwesomeIcon icon={faEye} />;

const Login = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    var [suchidden, ShowSuc] = useState(true);
    var [errhidden, ShowErr] = useState(true);
    var [user, SetUser] = useState('');
    var [suMSG, SetSuMsg] = useState('');
    var [erMSG, SetErMsg] = useState('');
    var [btndis, SetBtnDisable] = useState(true);
    const [session, setSession] = React.useState(null)
    const navigate = useNavigate();
    var role = '';
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    useEffect(() => {
        // window.history.forward();
        const rememberMe = localStorage.getItem('rememberMe') === 'true';
        if (rememberMe) {
            // Fetch the necessary data from localStorage and auto-fill the login form
            const username = localStorage.getItem('username');
            // Auto-fill the username input field
        }
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        })

        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })

        return () => subscription.unsubscribe()
    }, [])

    const loginSubmit = async (req_data, event) => {
        // if (rememberMe) {
        //     localStorage.setItem('rememberMe', 'true');
        //     localStorage.setItem('username', req_data.username);
        //     localStorage.setItem('password', req_data.password);
        // }
        //  else {
        //     localStorage.removeItem('rememberMe');
        //     localStorage.removeItem('username');
        // }
        /* var payload = {
                    'email_address': data.username,
                    'password': data.password,
                }
                payload = JSON.stringify(payload)
                var encrypt = AES.encrypt(payload, 'Z%5XH@c-@6m5TWqRbVeLh1iRXGTVUCj');
                var encr = encrypt.toString();
                console.log(encr);
                SetBtnDisable(btndis = false);
                // console.log(btndis);
                // e.preventDefault();
                console.log(data);
                data = {
                    // 'email_address': data.username,
                    'data': encr,
                }
        
                API.post('/login/', data, { headers: { 'Content-Type': 'application/json' } })
                    .then(response => {
                        console.log(response);
                        var decrypt = AES.decrypt(response.data.data, 'Z%5XH@c-@6m5TWqRbVeLh1iRXGTVUCj');
                        var dncr = decrypt.toString(enc.Utf8);
                        dncr = JSON.parse(dncr)
                        console.log('Decrypt: ', dncr);
                        console.log('Decrypt: ', dncr.msg);
                        SetSuMsg(suMSG = dncr.msg);
                        ShowSuc(!suchidden);
                        setTimeout(() => {
                            ShowSuc(suchidden = true);
                        }, 3000);
                        event.target.reset();
                        SetBtnDisable(btndis = true);
                    })
                    .catch(error => {
                        console.log(error.response);
                        var decrypt = AES.decrypt(error.response.data.data, 'Z%5XH@c-@6m5TWqRbVeLh1iRXGTVUCj');
                        var dncr = decrypt.toString(enc.Utf8);
                        dncr = JSON.parse(dncr)
                        SetErMsg(suMSG = dncr.msg);
                        ShowErr(!errhidden);
                        setTimeout(() => {
                            // console.log('hide');
                            ShowErr(errhidden = true);
                        }, 3000);
                        SetBtnDisable(btndis = true);
                    });
        */
        // console.log(req_data);
        // console.log(event);
        // return
        var response_data = await login_api(req_data.username, req_data.password);
        console.log(response_data);
        localStorage.setItem('username', response_data.user_name)

        SetBtnDisable(btndis = false);

        if (response_data.success) {
            // console.log(response_data)
            if (response_data.pwd_set == 1) {
                return navigate('reset-password')
            }
            localStorage.setItem('user_id', response_data.data.user.id)
            SetSuMsg(suMSG = response_data.msg);
            ShowSuc(!suchidden);
            setTimeout(() => {
                ShowSuc(suchidden = true);
            }, 3000);
            event.target.reset();
            SetBtnDisable(btndis = true);
        } else {
            console.log(response_data);
            if (response_data.msg == 'Invalid login credentials' || response_data.msg == 'Token has expired or is invalid') {
                SetErMsg('Invalid login credentials. Please ensure your username and password are correct');
            } else {
                SetErMsg(erMSG = response_data.msg);
            }

            ShowErr(!errhidden);
            setTimeout(() => {
                ShowErr(errhidden = true);
            }, 5000);
            SetBtnDisable(btndis = true);
        }
    }



    const logoutSubmit = async (req_data, event) => {
        let { error } = await supabase.auth.signOut()
        SetBtnDisable(btndis = false);

        if (!error) {
            SetSuMsg(suMSG = 'Logout Successfully');
            ShowSuc(!suchidden);
            setTimeout(() => {
                ShowSuc(suchidden = true);
            }, 3000);
            event.target.reset();
            SetBtnDisable(btndis = true);
        } else {
            SetErMsg(suMSG = error.msg);
            ShowErr(!errhidden);
            setTimeout(() => {
                ShowErr(errhidden = true);
            }, 3000);
            SetBtnDisable(btndis = true);
        }
    }

    if (!session) {
        return (
            <div className="container-bg pt-5">
                <MobileMenu />
                <div className="container">
                    <div className="loginForm">
                        <div className='toaster suc' hidden={suchidden}>
                            <h2>{suMSG}</h2>
                        </div>
                        {/* <div className='toaster fail' hidden={errhidden}>
                            <h2>{erMSG}</h2>
                        </div> */}
                        <div className='header d-flex justify-content-between d-none d-md-flex'>
                            <img className='logo' src={maxon_logo} alt="Maxon logo" />
                            <Menu />
                        </div>
                    </div>
                    <div className="login_content pt-md-5">
                        <h1>Welcome To Maxon</h1>
                        <p>Warranty & RMA Portal</p>
                    </div>
                    <div className="login_box">
                        <p>Log in with credentials</p>
                        <form onSubmit={handleSubmit(loginSubmit)}>
                            <div hidden={btndis} className='loader'>
                                <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                            </div>
                            <div className="password_icons">
                                <div className="pass-wrapper">
                                    <img src={user_icon} alt="User_icon" />
                                    <input placeholder="Username" id="username"
                                        {...register("username", {
                                            required: "Please enter an email address",
                                            validate: {
                                                maxLength: (v) =>
                                                    v.length <= 50 || "The email should have at most 50 characters",
                                                matchPattern: (v) =>
                                                    /^[a-zA-Z0-9]+([._-][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,3}$/.test(v) ||
                                                    "Please enter a valid email address",
                                            },
                                        })} />
                                </div>
                                {errors.username?.message && (
                                    <small className='errMsg'>{errors.username.message}</small>
                                )}
                            </div>
                            <div className=" password_icons">
                                <div className="pass-wrapper">
                                    <img src={pass_icon} alt="password_icon" />
                                    <input type={passwordShown ? "text" : "password"} placeholder="Password" id="password"
                                        {...register("password", {
                                            required: true,
                                        })} />
                                    <img className="eeyeee" src={view_png} alt="password_icon" onClick={togglePasswordVisiblity} />
                                    {/* <i onClick={togglePasswordVisiblity}>{eye}</i> */}
                                </div>
                                {errors.password?.type === 'required' && (
                                    <small className='errMsg'>Please enter a password</small>
                                )}
                            </div>
                            <div className='toster_Msg fail login_msg' hidden={errhidden}>
                                <span>{erMSG}.</span>
                            </div>
                            <div className="check_forget">
                                {/* <div className="input_text">
                                    <input type="checkbox" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
                                    <p>Remember me</p>
                                </div> */}
                                {/* <ForgotPassword /> */}
                                <Link to={"/forgetpassword"}>
                                    Forget Password?
                                </Link>
                            </div>
                            <div className="">
                                <div className="login" >
                                    <button className="login_btn"> <img className="login_arrow" src={log_into} />Login</button>
                                </div>
                            </div>
                            <div id='mob-user-tutor' className='pt-4 d-sm-block d-md-none'  >
                                <img src={NewUserIcon} alt="New User Icon" />
                                <a rel='noopener noreferrer' target="_blank" href="https://www.maxonlift.com/web-warranty-rma-tutorial"><span>USER TUTORIAL</span></a>
                            </div>
                        </form>
                    </div>
                    <p className="newuser text-center mt-4">New User? <Link to='/register'>Register</Link></p>
                    {/* <Footer /> */}
                </div>
                <div className="login_footer">
                    <Footer />
                </div>

            </div>
        );
    } else {
        /*
        return (

            <div> <h1 >Welcome</h1>
                <div className="container-bg p-5">
                    <div className="container">
                        <div className="loginForm">
                            <div className='toaster suc' hidden={suchidden}>
                                <h2>{suMSG}</h2>
                            </div>
                            <div className='toaster fail' hidden={errhidden}>
                                <h2>{erMSG}</h2>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(logoutSubmit)}>
                            <div hidden={btndis} className='loader'>
                                <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                            </div>
                            <div className="">
                                <div className="login" >
                                    <button className="login_btn"> <img className="login_arrow" src={log_into} />Logout</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
        */
        if (session.user.user_metadata.role == "admin") {
            return navigate('userslist')
        } else {
            return navigate("home");
        }

    }
};

export default Login;