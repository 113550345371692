import UserICO from '../assets/user-ico.png';
import Logo from '../assets/Logo.png';
import { Link } from 'react-router-dom'; 

const MobileMenu = () => {
    return (
        <div className='mobile-menu d-md-none d-sm-block'>
                <nav className="navbar navbar-light">
                    <div className="container-fluid">
                        
                        <img className='logo' src={Logo} alt="Maxon logo" />
                        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                            <img className='user-icon' src={UserICO} alt="User Icon" />
                        </button> */}
                    </div>
                </nav>
                <div className="collapse" id="navbarToggleExternalContent">
                    <div className="mobilemenu-area">
                        <ul className='menu-ul'>
                            
                        <li className="nut">
                        <Link to="/"> New User Tutorial</Link>
                        </li>
                        </ul>
                    </div>
                </div>
            </div>
    )
}

export default MobileMenu;