import supabase from './supabaseconnect';
import { createClient } from "@supabase/supabase-js";

const supabase2 = createClient(process.env.REACT_APP_API_URL, process.env.REACT_APP_API_KEY, { auth: { storageKey: "s1" } });

export async function user_approval_api(user_id) {
    console.log(user_id)
    var { data, error } = await supabase.from('user').select('*').eq('id', user_id);
    if (error) {
        var resepnse_data = { "success": false, "msg": error.message }
        return (resepnse_data);
    } else {
        if (data.length == 0) {
            var resepnse_data = { "success": false, "msg": "User not exists" }
            return (resepnse_data);
        } else {
            var email_id = data[0].email_address;
            var id = data[0].id;
            var UID = data[0].user_id;
            return create_user(email_id, id, UID);
        }
    }
}

export async function create_user(email_id, id, UID) {
    if (UID == null) {

        const password_length = 10;
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$&*';
        let NewPassword = '';
        for (let i = 0; i < password_length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            NewPassword += charset[randomIndex];
        }

        let { data, error } = await supabase2.auth.signUp({
            email: email_id,
            password: NewPassword
        })
        if (error) {
            var resepnse_data = { "success": false, "msg": error.message }
            return (resepnse_data);
        } else {
            var user_id = data.user.id;
            let { data2, error } = await supabase.from('user').update({ "status": '1', "pwd_set": 0, "user_id": user_id }).eq('id', id)
            if (error) {
                var resepnse_data = { "success": false, "msg": error.message }
                return (resepnse_data);
            } else {
                let { data2, error } = await supabase.from('user_temp_password').insert({ "email_address": email_id, "temp_password": NewPassword, "user_id": user_id });
                if (error) {
                    var resepnse_data = { "success": false, "msg": error.message }
                    return (resepnse_data);
                } else {
                    var resepnse_data = { "success": true, "msg": "Approved Successfully" }
                    console.log(resepnse_data);
                    return (resepnse_data);
                }
            }
        }
    } else {
        let { data2, error } = await supabase.from('user').update({ "status": '1' }).eq('id', id)
        if (error) {
            var resepnse_data = { "success": false, "msg": error.message }
            return (resepnse_data);
        } else {
            var resepnse_data = { "success": true, "msg": "Approved Successfully" }
            console.log(resepnse_data);
            return (resepnse_data);
        }
    }
}

export async function user_reject_api(user_id) {
    let { data, error } = await supabase.from('user').update({ "status": '2' }).eq('id', user_id)
    if (error) {
        var resepnse_data = { "success": false, "msg": error.message }
        return (resepnse_data);
    } else {
        var resepnse_data = { "success": true, "msg": "Rejected Successfully" }
        console.log(resepnse_data);
        return (resepnse_data);
    }
}

export async function user_remove_api(user_id) {
    var { data, error } = await supabase.from('user').update({ "status": '3' }).eq('id', user_id)
    if (error) {
        var resepnse_data = { "success": false, "msg": error.message }
        return (resepnse_data);
    } else {
        var { data, error } = await supabase.from('user').select('*').eq('id', user_id);
        if (error) {
            var resepnse_data = { "success": false, "msg": error.message }
            return (resepnse_data);
        } else {
            if(data.length > 0){
                const adminSupabase = createClient(process.env.REACT_APP_API_URL, process.env.REACT_APP_SERVICE_KEY);

                var { data1, error } =await adminSupabase.auth.admin.deleteUser(data[0].user_id);
            }
            var resepnse_data = { "success": true, "msg": "Removed Successfully" }
            console.log(resepnse_data);
            return (resepnse_data);
        }
    }
}

export async function edit_user_api(req_data) {
    let { data, error } = await supabase.from('user').update({ "first_name": req_data.first_name,"last_name": req_data.last_name,"account_no": req_data.account_no,"company_name": req_data.company_name }).eq('id', req_data.user_id)
    if (error) {
        var resepnse_data = { "success": false, "msg": error.message }
        return (resepnse_data);
    } else {
        var resepnse_data = { "success": true, "msg": "Updated Successfully" }
        console.log(resepnse_data);
        return (resepnse_data);
    }
}

export async function get_user_details_api(user_id) {
    let { data, error } = await supabase.from('user').select('*').eq('id', user_id)
    if (error) {
        var resepnse_data = { "success": false, "msg": error.message }
        return (resepnse_data);
    } else {
        if(data.length > 0){
            var resepnse_data = { "success": true, "msg": "User Details" , "data":data[0] }
            // console.log(resepnse_data);
            return (resepnse_data);
        }else{
            var resepnse_data = { "success": false, "msg": "User Not Found" }
            // console.log(resepnse_data);
            return (resepnse_data);
        }
    }
}

// export default user_approval_api;