import { React } from "react";
import "../pages/returns.css";
import search_icon from '../assets/search_icon.png';
import { Link } from "react-router-dom";
import ReturnMenu from "./returnsmenu";
import supabase from "../api/supabaseconnect.js";
import { get_rma_list_api } from "../api/rma_list_api.js";
import moment from "moment";
import edit_icon from "../assets/editing.png"
import { useState, useEffect } from "react";
import GIFLOADER from '../assets/loader.gif';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { useForm } from 'react-hook-form';
import filsvg from '../assets/filsvg.svg';
import addsvg from '../assets/addsvg.svg';
import newlogout from '../assets/logoutblack.svg';
import delete_icon from '../assets/delete.png';
import { rma_delete_api } from "../api/rma_delete_api.js";

function Return() {


    var [suchidden, ShowSuc] = useState(false);
    var [suMSG, SetSuMsg] = useState('');
    var [enableloader, SetBtnDisable] = useState(true);
    const [session, setSession] = useState(null)
    const [returns, SetReturns] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setrecordsPerPage] = useState(5);
    const [items_per_page, setItems_per_page] = useState(0);
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const allRecords = returns.slice(firstIndex, lastIndex);
    const npage = Math.ceil(returns.length / recordsPerPage);
    const pnumbers = [...Array(npage + 1).keys()].slice(1);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [filterDate, SetfilterDate] = useState([]);
    const [toggle, SetToggle] = useState(1);
    const [dates, SetDates] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const { register, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm();
    const [filterSearch, SetfilterSearch] = useState('');
    const [order, setOrder] = useState("ASC");
    const [activeyear, setActiveYear] = useState();
    const [popshow, setPopShow] = useState(false);
    const [claimid, setClmid] = useState();
    const [filterStatus, SetfilterStatus] = useState([{}])



    const isEmpty = returns.length === 0;
    useEffect(() => {
        window.scrollTo(0, 0);
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        })
        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })
        SetBtnDisable(false);
        var countfilteritmrma = JSON.parse(localStorage.getItem('rtnfilterdata'));
        if (countfilteritmrma != undefined && countfilteritmrma != '' && countfilteritmrma != null) {
            SetfilterStatus(countfilteritmrma)
            // setChecked(!checked)
        } else {
            countfilteritmrma = ''
        }
        if (countfilteritmrma && countfilteritmrma.draft === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('draft', countfilteritmrma.draft === true)
        }
        if (countfilteritmrma && countfilteritmrma.submitted === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('submitted', countfilteritmrma.submitted === true)
        }
        if (countfilteritmrma && countfilteritmrma.waitingonreturn === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('waitingonreturn', countfilteritmrma.waitingonreturn === true)
        }
        if (countfilteritmrma && countfilteritmrma.closed === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('closed', countfilteritmrma.closed === true)
        }
        if (countfilteritmrma && countfilteritmrma.declined === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('declined', countfilteritmrma.declined === true)
        } 
        if (countfilteritmrma && countfilteritmrma.creditprocessed === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('creditprocessed', countfilteritmrma.creditprocessed === true)
        }
        if (countfilteritmrma && countfilteritmrma.inprocess === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('inprocess', countfilteritmrma.inprocess === true)
        }
        var splitdaterma = [];
        var filterdaterma = (localStorage.getItem('rtnfildate'));
        console.log(filterdaterma)
        if (filterdaterma != undefined && filterdaterma != '' && filterdaterma != null) {
            var splitdaterma = filterdaterma.split(',');
            console.log(splitdaterma)
            setStartDate(splitdaterma[0]);
            setEndDate(splitdaterma[1]);
            SetfilterDate(splitdaterma)
            if (splitdaterma != []) {
                document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            }
            // setChecked(!checked)
        } else {
            filterdaterma = []
        }
        var response_data = get_rma_list_api('', splitdaterma, '', '', countfilteritmrma).then(res => {
            console.log(res.data)
            if (res.data) {
                SetReturns(res.data)
                SetBtnDisable(true);
                const dateObj = new Date();
                const year = dateObj.getFullYear();
                setActiveYear(year)
            }
            var crtpage = localStorage.getItem('fromdetails')
            var countperpage = localStorage.getItem('currentpage');
            if (crtpage == 'pagecount' && countperpage != undefined && countperpage != '') {
                setrecordsPerPage(countperpage);
            }
            var claim_page_start = localStorage.getItem('return_page_start');
            var claim_curr_page = localStorage.getItem('return_curr_page');

            if (claim_page_start != undefined && claim_page_start != '' && claim_curr_page != undefined && claim_curr_page != '') {
                setItems_per_page(parseInt(claim_page_start));
                setCurrentPage(parseInt(claim_curr_page));
            }
        })
        return () => subscription.unsubscribe()
    }, [])

    function deleteclaim(evt) {
        // console.log(evt)
        setClmid(evt)
        setPopShow(!popshow)
    }
    function staysamepage() {
        setPopShow(!popshow)
    }
    function deleteClaim() {
        setPopShow(!popshow)
        SetBtnDisable(false);
        var response_data = rma_delete_api(claimid).then(res => {
            console.log(res)
            SetSuMsg(res.msg)
            ShowSuc(true)
            setCurrentPage(1)
            setItems_per_page(0)
            setTimeout(() => {
                ShowSuc(false)
                SetSuMsg('')
                SetBtnDisable(true);
            }, 3000);
            var response_data = get_rma_list_api('', filterDate, '', '', filterStatus).then(res => {
                // console.log(res.data)
                SetReturns(res.data);
                // SetBtnDisable(true);
            })

        })
    }
    function details_page(res, status) {
        console.log(status)
        if (status == "0") {
            return navigate(`/editreturns/${res}`);
        } else {
            return navigate(`/returndetails/${res}`);
        }
    }
    function editPage(evt) {
        navigate(`/editreturns/${evt}`);
        return true
    }

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    function pageChange(evt) {
        setCurrentPage(1);
        setItems_per_page(0)
        setrecordsPerPage(evt.target.value);
        localStorage.setItem('currentpage', evt.target.value)
        localStorage.setItem('return_page_start', 0)
        localStorage.setItem('return_curr_page', 1)
    }
    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
            var start_ind = (currentPage - 2) * recordsPerPage;
            setItems_per_page(start_ind)
            localStorage.setItem('return_page_start', start_ind)
            localStorage.setItem('return_curr_page', (currentPage - 1))
        }
    }
    // currentPage
    function changeCurPage(id) {
        console.log(id)
        var start_ind = (id - 1) * recordsPerPage;
        setItems_per_page(start_ind)
        setCurrentPage(id)
    }
    // nextPage
    function nextPage() {
        if (currentPage !== npage) {
            var start_ind = currentPage * recordsPerPage;
            setItems_per_page(start_ind)
            setCurrentPage(currentPage + 1)
            localStorage.setItem('return_page_start', start_ind)
            localStorage.setItem('return_curr_page', (currentPage + 1))
        }
    }
    function updateToggle(id) {
        SetToggle(id);
    }
    const filterSubmit = (req_data) => {
        console.log(req_data)
        SetfilterStatus(req_data)
        document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
        localStorage.setItem('return_page_start', 0)
        localStorage.setItem('return_curr_page', 1)
        setItems_per_page(0)
        setCurrentPage(1)
        SetfilterDate(dates);
        setShow(!show)
        SetBtnDisable(false);
        dates.push(startDate)
        dates.push(endDate)
        // console.log(dates)
        var response_data = get_rma_list_api('', dates, '', '', req_data).then(res => {
            console.log(res)
            SetReturns(res.data);
            SetBtnDisable(true);
            SetDates([])
            localStorage.setItem('rtnfilterdata', JSON.stringify(req_data))
            if (dates != undefined && dates != '' && dates != null && dates[0] != undefined && dates[1] != undefined) {
                localStorage.setItem('rtnfildate', dates)
            }
        })
    }
    function ClearFilter() {
        document.getElementById("divsection").style.backgroundColor = "white";
        localStorage.removeItem('rtnfilterdata');
        localStorage.removeItem('rtnfildate');
        localStorage.setItem('return_page_start', 0)
        localStorage.setItem('return_curr_page', 1)
        reset();
        setShow(!show)
        SetBtnDisable(false);
        // SetDates([])
        SetfilterDate([]);
        SetfilterStatus({});
        setCurrentPage(1)
        setItems_per_page(0)
        setStartDate()
        setEndDate()
        var response_data = get_rma_list_api('', '', '', '', '').then(res => {
            SetReturns(res.data);
            SetBtnDisable(true);
            // setStartDate(new Date())
        })
    }
    // function searchResults(searchResult) {
    //     console.log(searchResult);
    //     SetfilterSearch(searchResult)
    //     setCurrentPage(1);
    //     setItems_per_page(0)
    //     var response_data = get_rma_list_api(searchResult, filterDate).then(res => {
    //         SetReturns(res.data)
    //     })
    // }
    var search_res = watch('user_search')
    useEffect(() => {
        // SetfilterSearch(searchResult)
        setCurrentPage(1);
        setItems_per_page(0)
        // console.log(result.target.value);
        const delayApiCall = setTimeout(() => {
            var response_data = get_rma_list_api(search_res, filterDate, '', '', filterStatus).then(res => {
                console.log(res.data)
                SetReturns(res.data)
            })
        }, 1000);
        return () => clearTimeout(delayApiCall);
    }, [search_res])
    const sorting = (col) => {
        console.log(col)
        setCurrentPage(1)
        setItems_per_page(0)
        localStorage.setItem('return_page_start', 0)
        localStorage.setItem('return_curr_page', 1)
        // setOrder('ASC');
        if (order === 'ASC') {
            var response_data = get_rma_list_api(filterSearch, filterDate, col, order, filterStatus).then(res => {
                console.log(res)
                SetReturns(res.data)
            })
            setOrder('DSC');
        }
        if (order === 'DSC') {
            var response_data = get_rma_list_api(filterSearch, filterDate, col, order, filterStatus).then(res => {
                SetReturns(res.data)
            })
            setOrder('ASC');
        }

    }
    return (
        <div className="menuuuu">
            <ReturnMenu />
            <div className="container details_All">
                <div hidden={enableloader} className='loader'>
                    <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                </div>
                {suchidden && < div className='toaster suc returns_suc'>
                    <h2>{suMSG}</h2>
                </div>}
                <div className="claim_top_bar">
                    <div className=" user_topbar">
                        <h3>RMA</h3>
                        <div className="warrtylog" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            <div className="logImg"><img src={newlogout}></img></div>
                            <div className="warrntyp" >
                                <p>LOG OUT</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="claim_top_btns rtn_top">
                    <div className="control_search">
                        <form className="search-area">
                            <img src={search_icon} alt="search-icon" />
                            {/* <input type="text"
                                placeholder="Search..." onChange={(e) => searchResults(e.target.value)} /> */}
                            <input type="text"
                                placeholder="Search..."{...register('user_search')} />
                        </form>
                    </div>
                    <div className="claimBtns" onClick={() => setShow(!show)} >
                        <button className="filter_btnn" id="divsection">
                            <img src={filsvg}></img>
                            Filter
                        </button>
                        <Link to="/addreturns">  <button className="claim_btn"> <img src={addsvg}></img>
                            Add RMA</button></Link>
                    </div>
                </div>
                <div>
                    {
                        popshow && <div className="CanPop">
                            <div className="can_popTotal">
                                <div className='can_title'>
                                    Confirmation
                                </div>
                                <hr />
                                <div className='cancel_content'>
                                    <p>
                                        Are you sure you want to delete the RMA?
                                    </p>
                                </div>
                                <hr />

                                <div className='cancel_btns'>
                                    <button className='can_no' onClick={staysamepage}>No</button>
                                    <button className='can_yes' onClick={deleteClaim}>Yes</button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="filter_popTotal">
                    {
                        show && <div className="claimPop filres">
                            <div className="claim-popTotal">
                                <div className="popSubclass">
                                    <div className="claim-pop_left">
                                        <div>
                                            <ul>
                                                <li onClick={() => updateToggle(1)}>Status</li>
                                                <li onClick={() => updateToggle(2)}>Date</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="claim-pop_right">
                                        <form onSubmit={handleSubmit(filterSubmit)}>
                                            <div className={toggle === 1 ? "show-content" : "content"}>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" id="draft"  {...register("draft")} />
                                                    <label htmlFor="draft" className="form-check-label" >
                                                        Draft
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="submitted" {...register("submitted")} />
                                                    <label htmlFor="submitted" className="form-check-label" >
                                                        Submitted
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="waitingonreturn" {...register("waitingonreturn")} />
                                                    <label htmlFor="waitingonreturn" className="form-check-label" >
                                                        Waiting on Return
                                                    </label>
                                                </div>
                                                {/* <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="entryhold" {...register("entryhold")} />
                                                    <label htmlFor="entryhold" className="form-check-label" >
                                                        Entry Hold
                                                    </label>
                                                </div> */}
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="closed" {...register("closed")} />
                                                    <label htmlFor="closed" className="form-check-label" >
                                                        Closed
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="declined" {...register("declined")} />
                                                    <label htmlFor="declined" className="form-check-label" >
                                                        Declined
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="creditprocessed" {...register("creditprocessed")} />
                                                    <label htmlFor="creditprocessed" className="form-check-label" >
                                                        Credit Processed
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="inprocess" {...register("inprocess")} />
                                                    <label htmlFor="inprocess" className="form-check-label" >
                                                        In Process
                                                    </label>
                                                </div>
                                            </div>
                                            <div className={toggle === 2 ? "show-content" : "content"}>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={onChange}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    selectsRange
                                                    inline
                                                    maxDate={new Date()}
                                                />
                                            </div>
                                            <div className="filter_btns ">
                                                <button className="clr_btn" onClick={ClearFilter}>Clear</button>
                                                <button className="filCan" onClick={() => setShow(!show)}>Cancel </button>
                                                <button type="submit" className="addclaim-sub-btn"  >Apply </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="claims_card">
                    {/* <div className="card">
                        <div className="card-body"> */}
                    <div className="container">
                        <div className="returns_table table-responsive">
                            {isEmpty ? (
                                <p className="display_text">No returns to show</p>
                            ) : (
                                <table className="table desc_table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col" onClick={() => sorting('rma_no')}>
                                                <b>RMA ID</b> <i className="fa fa-fw fa-sort"></i></th>
                                            <th scope="col" onClick={() => sorting('return_date')}>
                                                <b>Claim Date</b> <i className="fa fa-fw fa-sort"></i></th>
                                            <th scope="col" onClick={() => sorting('status_value')}>
                                                <b>Status</b> <i className="fa fa-fw fa-sort"></i></th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allRecords && allRecords?.map((res, index) =>
                                            <tr key={index} data-id={res.id} >
                                                {/* <React.Fragment > */}
                                                <td onClick={() => details_page(res.id, res.status)}>{(items_per_page + index + 1)}</td>
                                                <td onClick={() => details_page(res.id, res.status)}> {res.rma_no
                                                }</td>
                                                <td onClick={() => details_page(res.id, res.status)}>
                                                    {moment(res.return_date).format("MM-DD-YYYY")} </td>
                                                <td onClick={() => details_page(res.id, res.status)}>
                                                    {(() => {
                                                        if (res.status_value === "Draft") {
                                                            return <p className="draft_color">Draft</p>;
                                                        } else if (res.status_value === "Submitted") {
                                                            return <p className="sub_color">Submitted</p>;
                                                        } else if (res.status_value === "Closed") {
                                                            return <p className="sub_color">Closed</p>;
                                                        } else if (res.status_value === "Failed") {
                                                            return <p className="del_color">Failed</p>;
                                                        } else {
                                                            return <p>{res.status_value}</p>
                                                        }
                                                    })()}
                                                </td>
                                                {/* </React.Fragment> */}
                                                <td >
                                                    {(() => {
                                                        if (res.status === 0) {
                                                            return <div className="rtndelimg">
                                                                <img className="rtn_edit" src={edit_icon} onClick={() => editPage(res.id)} ></img>
                                                                <img className="rtn_edit" src={delete_icon} onClick={() => deleteclaim(res.id)} />
                                                            </div>
                                                        } else {
                                                            return <button className="view_btn_rtn" onClick={() => details_page(res.id, res.status)}>View</button>
                                                        }
                                                    })()}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                            )}
                            <div className="mob_card">
                                <div className="card_data">
                                    {allRecords?.map((res, index) =>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="Card_top">
                                                    <div>
                                                        {(() => {
                                                            if (res.status_value === "Draft") {
                                                                return <p className="draft_color">
                                                                    <ul> <li>
                                                                        Draft
                                                                    </li></ul>
                                                                </p>;
                                                            } else if (res.status_value === "Submitted") {
                                                                return <p className="sub_color">
                                                                    <ul><li>
                                                                        Submitted
                                                                    </li></ul>
                                                                </p>;
                                                            } else if (res.status_value === "Closed") {
                                                                return <p className="sub_color">
                                                                    <ul><li>
                                                                        Closed
                                                                    </li></ul>
                                                                </p>;
                                                            } else if (res.status_value === "Failed") {
                                                                return <p className="del_color">
                                                                    <ul><li>
                                                                        Failed
                                                                    </li></ul>
                                                                </p>;
                                                            } else {
                                                                return <p>{res.status_value}</p>
                                                            }
                                                        })()}
                                                    </div>
                                                    <div className="edit_icon_card rtn_view">
                                                        {(() => {
                                                            if (res.status === 0) {
                                                                return <div className="rtndelimg">
                                                                    <img src={edit_icon} onClick={() => editPage(res.id)} ></img>
                                                                    <img className="rtn_edit" src={delete_icon} onClick={() => deleteclaim(res.id)} />
                                                                </div>
                                                            } else {
                                                                return <span onClick={() => details_page(res.id, res.status)}>View</span>
                                                            }
                                                        })()}
                                                    </div>
                                                </div>
                                                {/* <hr></hr> */}
                                                <div className="row card_top"  >
                                                    <div className="col-sm-6">
                                                        <div className="mob_data_lable">
                                                            <label>Claim Id</label>
                                                            {res.rma_no}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="mob_data_lable">
                                                            <label>Claim Date</label>
                                                            {moment(res.return_date).format("MM-DD-YYYY")}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="claim_pagination claims_page rtn_pages" >
                                <div className="itemsPage custom-select">
                                    <p>Items Per Page:</p>
                                    <select value={recordsPerPage} className="form-select" onChange={(e) => pageChange(e)}>
                                        <option >5</option>
                                        <option>10</option>
                                        <option>25</option>
                                        <option>50</option>
                                        <option>100</option>
                                    </select>
                                </div>
                                <div className="page_wid">
                                    <span>Returns ({returns.length})</span>
                                    <nav >
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <a className="page-link"
                                                    onClick={prePage}>Prev</a>
                                            </li>
                                            {/* <div> */}
                                            {/* {
                                                pnumbers.map((n, i) => (
                                                    <div className="card_pagination">
                                                        <li className={`page-item ${currentPage === n ? 'active' : ""}`} key={i}>
                                                            <a className="page-link"
                                                                onClick={() => changeCurPage(n)}>
                                                                {n}
                                                            </a>
                                                        </li>
                                                    </div>
                                                ))
                                            } */}
                                            {/* </div> */}
                                            <li className="page-item">
                                                <a className="page-link"
                                                    onClick={nextPage}>Next</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* </div>
                    </div> */}
                </div>
                <div className=" claim_footer">
                    <div className="footer_copy">
                        <p>Copyright &copy; {activeyear} Maxon Lift Crop. All rights reserved</p>
                    </div>
                    <div className="home_content">
                        <div className="clmuser"  >
                        <a rel='noopener noreferrer' target="_blank" href="https://www.maxonlift.com/web-warranty-rma-tutorial"><span>User Tutorial</span></a>
                        </div>
                        <Link to={'/privacy-policy'}>
                            Policy Privacy</Link>
                        <Link to={'/terms-Conditions'}>
                            Terms & Conditions</Link>
                    </div>
                </div>
            </div>
        </div>

    )

}



export default Return;