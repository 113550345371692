import supabase from './supabaseconnect';

export async function delete_claim_api(req_data) {
    // console.log(req_data)
    // console.log(req_data.claim_id)

    var { error } = await supabase.auth.refreshSession();

    var { data, error } = await supabase.from('claim').update({"status": '2' }).eq('id', req_data);
    if (error) {
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {
        var response_data = {"success":true,"msg":"Deleted Successfully"}
        return(response_data);
    }
}
