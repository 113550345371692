import supabase from './supabaseconnect';

export async function profile_api() {

    var { error } = await supabase.auth.refreshSession();

    const { data: { user } } = await supabase.auth.getUser()

    if (!user) {
        var resepnse_data = { "success": false, "msg": "User Not Found" }
        return (resepnse_data);
    } else {
        var email_id = user.email;
        var { data, error } = await supabase.from('user').select('*').eq('email_address', email_id).eq('status','1');
        if (error) {
            var resepnse_data = { "success": false, "msg": error.message }
            return (resepnse_data);
        } else {
            var user_name = data[0].first_name + ' ' + data[0].last_name;
            var resepnse_data = { "success": true, "msg": "Successfully", "user_name": user_name }
            // console.log(resepnse_data);
            // var user_name = data[0].first_name + ' ' + data[0].last_name;
            var resepnse_data = { "success": true, "msg": "Successfully", "data": data }
            // console.log(resepnse_data);
            return (resepnse_data);
        }
    }
}

export async function changepassword_api(password) {

    var { error } = await supabase.auth.refreshSession();

    var { data, error } = await supabase.auth.updateUser({
        password: password
    });
    if (error) {
        var resepnse_data = { "success": false, "msg": error.message }
        return (resepnse_data);
    } else {


        var resepnse_data = { "success": true, "msg": "Password Changed Successfully" }
        return (resepnse_data);

    }
}