import supabase from './supabaseconnect';
import moment from 'moment';

export async function get_rma_list_api(search, filter_date, col, order,filter_status) {
    // console.log(filter_date)

    var rma_status = [];
    // console.log(filter_status)

    if(filter_status.draft){
        rma_status.push('Draft');
    }
    if(filter_status.submitted){
        rma_status.push('Submitted');
    }
    if(filter_status.approved){
        rma_status.push('Approved');
    }
    if(filter_status.rejected){
        rma_status.push('Rejected');
    }
    if(filter_status.closed){
        rma_status.push('Closed');
    }
    if(filter_status.failed){
        rma_status.push('Failed');
    }
    if(filter_status.waitingonreturn){
        rma_status.push('Waiting on Return');
    }
    if(filter_status.creditprocessed){
        rma_status.push('Credit Processed');
    }
    if(filter_status.inprocess){
        rma_status.push('In Process');
    }
    if(filter_status.declined){
        rma_status.push('Declined');
    }

    if (filter_date.length > 0) {
        if (filter_date[0] == undefined && filter_date[1] == undefined) {
            filter_date = [];
        } else if (filter_date[1] == null) {
            filter_date[1] = undefined;
        }
    }

    if (search != undefined && search != '') {
        search = search.replace(/,/g, " ");
    }

    var { error } = await supabase.auth.refreshSession();

    if ((col != undefined && order != undefined && col != '' && order != '') && (search != undefined && search != '') && rma_status.length > 0 && filter_date.length > 0) {
        var from_date = moment(filter_date[0]).format('YYYY-MM-DD');
        var to_date = moment(filter_date[1]).format('YYYY-MM-DD');
        var filter_values = "rma_no.ilike.%" + search + "%,invoice_no.ilike.%" + search + "%";

        if (order == 'DSC') {
            var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').in('status_value',rma_status).gte('return_date', from_date).lte('return_date', to_date).or(filter_values).order(col, { ascending: false });
        } else {
            var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').in('status_value',rma_status).gte('return_date', from_date).lte('return_date', to_date).or(filter_values).order(col);
        }
    } else if ((col != undefined && order != undefined && col != '' && order != '') && (search != undefined && search != '') && rma_status.length > 0) {
        var filter_values = "rma_no.ilike.%" + search + "%,invoice_no.ilike.%" + search + "%";
        if (order == 'DSC') {
            var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').in('status_value',rma_status).or(filter_values).order(col, { ascending: false });
        } else {
            var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').in('status_value',rma_status).or(filter_values).order(col);
        }
    } else if ((col != undefined && order != undefined && col != '' && order != '') && rma_status.length > 0 && filter_date.length > 0) {
        var from_date = moment(filter_date[0]).format('YYYY-MM-DD');
        var to_date = moment(filter_date[1]).format('YYYY-MM-DD');
        if (order == 'DSC') {
            var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').in('status_value',rma_status).gte('return_date', from_date).lte('return_date', to_date).order(col, { ascending: false });
        } else {
            var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').in('status_value',rma_status).gte('return_date', from_date).lte('return_date', to_date).order(col);
        }
    } else if ((col != undefined && order != undefined && col != '' && order != '') && (search != undefined && search != '')) {
        var filter_values = "rma_no.ilike.%" + search + "%,invoice_no.ilike.%" + search + "%";
        if (order == 'DSC') {
            var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').or(filter_values).order(col, { ascending: false });
        } else {
            var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').or(filter_values).order(col);
        }
    } else if ((col != undefined && order != undefined && col != '' && order != '') && filter_date.length > 0) {
        var from_date = moment(filter_date[0]).format('YYYY-MM-DD');
        var to_date = moment(filter_date[1]).format('YYYY-MM-DD');
        if (order == 'DSC') {
            var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').gte('return_date', from_date).lte('return_date', to_date).order(col, { ascending: false });
        } else {
            var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').gte('return_date', from_date).lte('return_date', to_date).order(col);
        }
    } else if ((search != undefined && search != '') && rma_status.length > 0) {
        var filter_values = "rma_no.ilike.%" + search + "%,invoice_no.ilike.%" + search + "%";
        var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').in('status_value',rma_status).or(filter_values);
    } else if (filter_date.length > 0 && rma_status.length > 0) {
        var from_date = moment(filter_date[0]).format('YYYY-MM-DD');
        var to_date = moment(filter_date[1]).format('YYYY-MM-DD');
        var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').in('status_value',rma_status).gte('return_date', from_date).lte('return_date', to_date);
    } else if (col != undefined && order != undefined && col != '' && order != '' && rma_status.length > 0) {
        if (order == 'DSC') {
            var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').in('status_value',rma_status).order(col, { ascending: false });
        } else {
            var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').in('status_value',rma_status).order(col);
        }
    } else if ((search != undefined && search != '')) {
        var filter_values = "rma_no.ilike.%" + search + "%,invoice_no.ilike.%" + search + "%";
        var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').or(filter_values);
    } else if (filter_date.length > 0) {
        var from_date = moment(filter_date[0]).format('YYYY-MM-DD');
        var to_date = moment(filter_date[1]).format('YYYY-MM-DD');
        var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').gte('return_date', from_date).lte('return_date', to_date);
    } else if (col != undefined && order != undefined && col != '' && order != '') {
        if (order == 'DSC') {
            var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').order(col, { ascending: false });
        } else {
            var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').order(col);
        }
    } else if (rma_status.length > 0) {
        var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').in('status_value',rma_status);
    } else {
        var { data, error } = await supabase.from('rma_base_table').select('*').neq('status','2').order('created_at', { ascending: false });
    }

    if (error) {
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {
        var response_data = { "success": true, "msg": "RMA List", "data": data }
        return (response_data);
    }
}
