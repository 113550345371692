import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import MobileMenu from "./MobileMenu";
import pass_icon from "../assets/pass_lock.png";
import maxon_logo from '../assets/max_logo.png';
import Menu from "./Menu";
import GIFLOADER from '../assets/loader.gif';
import Footer from "./Footer";
import resetpassword_api from '../api/resetpassword_api.js';
import { Link, redirect, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faL } from "@fortawesome/free-solid-svg-icons";
import view_png from "../assets/view.png";
import { logout_api, reset_cancel_api } from '../api/logout_api.js';






const eye = <FontAwesomeIcon icon={faEye} />;

const ResetPassword = () => {

    var [enableloader, SetBtnDisable] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [cnfpasswordShown, setCnfPasswordShown] = useState(false);

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues, watch,
    } = useForm();
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const toggleCnfPasswordVisiblity = () => {
        setCnfPasswordShown(cnfpasswordShown ? false : true);
    };
    useEffect(() => {
        // window.history.forward();
        setTimeout(() => {
            SetBtnDisable(enableloader = true);
        }, 2000);
    }, []);

    var [suchidden, ShowSuc] = useState(true);
    var [errhidden, ShowErr] = useState(true);

    var [suMSG, SetSuMsg] = useState('');
    var [erMSG, SetErMsg] = useState('');


    const handleKeyDown = event => {
        if (event.keyCode === 32) {
            event.preventDefault();
        }
    };

    const onResSubmit = async (req_data, event) => {
        // event.preventDefault();
        console.log(req_data);
        SetBtnDisable(enableloader = false);
        // return
        var response_data = await resetpassword_api(req_data.first_password);
        console.log(response_data);

        if (response_data.success) {
            SetSuMsg(suMSG = response_data.msg);
            ShowSuc(!suchidden);
            setTimeout(() => {
                ShowSuc(!suchidden);
                SetBtnDisable(enableloader = false);
                return navigate("/");
            }, 3000);
        } else {
            SetErMsg(suMSG = response_data.msg);
            ShowErr(!errhidden);
            setTimeout(() => {
                ShowErr(errhidden = true);
                navigate("/");
            }, 3000);
            // SetBtnDisable(enableloader = true);
        }
    }
    const logoutUser = async (event) => {
        event.preventDefault();
        var response_data = await reset_cancel_api();
        // console.log(response_data);
        navigate("/");
        SetBtnDisable(enableloader = false);
        if (response_data.success) {
            SetSuMsg(suMSG = response_data.msg);
            ShowSuc(!suchidden);
            setTimeout(() => {
                localStorage.clear();
                ShowSuc(suchidden = true);

            }, 3000);
            SetBtnDisable(enableloader = true);
        } else {
            SetErMsg(suMSG = response_data.msg);
            ShowErr(!errhidden);
            setTimeout(() => {
                ShowErr(errhidden = true);
            }, 3000);
            SetBtnDisable(enableloader = true);
        }
    }
    return (
        <div className='container-bg p-5'>
            {/* <MobileMenu /> */}
            <div className='container'>
                <div className='bg-grey-top'>
                    <div className='toaster suc' hidden={suchidden}>
                        <h2>{suMSG}</h2>
                    </div>
                    <div className='toaster fail' hidden={errhidden}>
                        <h2>{erMSG}</h2>
                    </div>
                    <div className='header reset_pass ' style={{ textAlign: 'center' }}>
                        <img className='logo' src={maxon_logo} alt="Maxon logo" />
                        {/* <Menu /> */}
                    </div>
                    <div className='row'>
                        <div className='col-md-6 mx-auto'>
                            <div className='bg-black-bottom pt-5'>
                                <form className='reset form-area p-5 reset_title' >
                                    <div hidden={enableloader} className='loader'>
                                        <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                                    </div>
                                    <h5 className="text-center">Reset Password</h5>
                                    <div className="create_passsword">
                                        <img src={pass_icon} alt="password_icon" />
                                        <input type={passwordShown ? "text" : "password"} placeholder="New Password" id="first_password" onKeyDown={handleKeyDown}
                                            {...register("first_password", {
                                                required: true,
                                                minLength: 8
                                            })} />
                                        <img className="eeyeee" src={view_png} alt="password_icon" onClick={togglePasswordVisiblity} />
                                        <br />
                                        {errors.first_password?.type === 'required' && (
                                            <small className='errMsg'>Password is required</small>
                                        )}
                                        {errors?.first_password?.type === "minLength" && (
                                            <small className='errMsg'>Password cannot less than 8 characters</small>
                                        )}
                                    </div>
                                    <div className="create_passsword">
                                        <img src={pass_icon} alt="password_icon" />
                                        <input type={cnfpasswordShown ? "text" : "password"} placeholder="Confirm Password" id="confirm_password" onKeyDown={handleKeyDown}
                                            {...register("confirm_password", {
                                                required: true,
                                                validate: (val) => {
                                                    if (watch('first_password') != val) {
                                                        return "Your passwords do no match";
                                                    }
                                                },
                                            })} />
                                        <img className="eeyeee" src={view_png} alt="password_icon" onClick={toggleCnfPasswordVisiblity} />
                                        <br />
                                        {errors.confirm_password?.type === 'required' && (
                                            <small className='errMsg'>Password is required</small>
                                        )}

                                        {errors.confirm_password?.type === 'validate' && (
                                            <small className='errMsg'>Your passwords do no match</small>
                                        )}
                                    </div>
                                    <div className="craft_serch btn-area reset_btn">
                                        <button className="resCan" onClick={logoutUser} >
                                            Cancel</button>
                                        <button className='btn reset-btn reset_btn' onClick={handleSubmit(onResSubmit)}>
                                            Reset</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="forget_foot">
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;