import supabase from './supabaseconnect';

export async function edit_return_claim_api(req_data) {
    console.log(req_data);

    var { error } = await supabase.auth.refreshSession();

    var invoice_no = '';

    req_data.parts.forEach((val,index) => {
        if(index != 0){
            invoice_no += ",";
        }
        invoice_no += val.invoice_no;
    });

    if(req_data.status == 1){
        req_data.status_value = 'Submitted';
    }else{
        req_data.status_value = 'Draft';
    }

    var { data, error } = await supabase.from('rma_base_table').update({ "invoice_no": invoice_no, "status": req_data.status, "return_date": req_data.returnsdate,"status_value":req_data.status_value }).eq('id', req_data.rma_id);

    if (error) {
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {
        var { data1, error } = await supabase.from('rma_return_part_list').delete().eq('rma_id', req_data.rma_id)
        if (error) {
            var response_data = { "success": false, "msg": error.message }
            return (response_data);
        } else {
            var return_details = []
            req_data.parts.forEach((val, index) => {
                var part_line_no = index + 1;

                if(val.invoice_part_list != undefined && val.invoice_part_list != null && val.invoice_part_list != ''){
                    val.invoice_part_list = val.invoice_part_list;
                }else{
                    val.invoice_part_list = [];
                }

                return_details.push({ "rma_id": req_data.rma_id, "invoice_no": val.invoice_no, "invoice_line": val.invoice_no_line, "part_no": val.part_no, "part_description": val.part_description, "return_qty": val.return_qty, "reason_code": val.reason_code, "replace_required": val.replace_required, "pickup_arrangements": val.pickup_arrangements, "comments": val.comments, "part_line_no": part_line_no, "status": req_data.status, "invoice_part_list": val.invoice_part_list })
            });

            var { data1, error } = await supabase.from('rma_return_part_list').insert(return_details);

            if (error) {
                var response_data = { "success": false, "msg": error.message }
                return (response_data);
            } else {

                if (req_data.status == 1) {
                    var { data1, error } = await supabase.from('rma_epicor_sync_log').insert({ "rma_id": req_data.rma_id });;

                    if (error) {
                        var response_data = { "success": false, "msg": error.message }
                        return (response_data);
                    } else {
                        var response_data = { "success": true, "msg": "RMA Submitted Successfully" }
                        return (response_data);
                    }
                } else {
                    var response_data = { "success": true, "msg": "Draft Saved Successfully" }
                    return (response_data);
                }
            }
        }
    }
}
