
import supabase from './supabaseconnect';

async function user_list_api(filter_status, col, order, user_search) {
    var user_status = [];
    console.log(user_search)
    if (filter_status.approved) {
        user_status.push('1');
    }
    if (filter_status.not_approved) {
        user_status.push('0');
    }
    if (filter_status.rejected) {
        user_status.push('2');
    }

    if(user_search != undefined && user_search != ''){
        user_search = user_search.replace(/,/g, " ");
    }

    var { error } = await supabase.auth.refreshSession();

    if ((col != undefined && order != undefined && col != '' && order != '') || (user_search != undefined && user_search != '') || user_status.length > 0) {
        if (col != undefined && order != undefined && col != '' && order != '' && user_search != undefined && user_search != '' && user_status.length > 0) {
            var filter_values = "first_name.ilike.%" + user_search + "%,last_name.ilike.%" + user_search + "%,account_no.ilike.%" + user_search + "%,company_name.ilike.%" + user_search + "%,email_address.ilike.%" + user_search + "%";
            if (order == 'DSC') {
                var { data, error } = await supabase.from('user_list').select('*').neq('status', '3').neq('role', '1').in('status', user_status).or(filter_values).order(col, { ascending: false });
            } else {
                var { data, error } = await supabase.from('user_list').select('*').neq('status', '3').neq('role', '1').in('status', user_status).or(filter_values).order(col);
            }
        } else if (col != undefined && order != undefined && col != '' && order != '' && user_status.length > 0) {
            if (order == 'DSC') {
                var { data, error } = await supabase.from('user_list').select('*').neq('status', '3').neq('role', '1').in('status', user_status).order(col, { ascending: false });
            } else {
                var { data, error } = await supabase.from('user_list').select('*').neq('status', '3').neq('role', '1').in('status', user_status).order(col);
            }
        } else if (user_search != undefined && user_search != '' && user_status.length > 0) {
            var filter_values = "first_name.ilike.%" + user_search + "%,last_name.ilike.%" + user_search + "%,account_no.ilike.%" + user_search + "%,company_name.ilike.%" + user_search + "%,email_address.ilike.%" + user_search + "%";
            var { data, error } = await supabase.from('user_list').select('*').neq('status', '3').neq('role', '1').in('status', user_status).or(filter_values);
        } else if (user_status.length > 0) {
            var { data, error } = await supabase.from('user_list').select('*').neq('status', '3').neq('role', '1').in('status', user_status);
        } else if (col != undefined && order != undefined && col != '' && order != '') {
            if (order == 'DSC') {
                var { data, error } = await supabase.from('user_list').select('*').neq('status', '3').neq('role', '1').order(col, { ascending: false });
            } else {
                var { data, error } = await supabase.from('user_list').select('*').neq('status', '3').neq('role', '1').order(col);
            }
        } else if (user_search != undefined && user_search != '') {
            var filter_values = "first_name.ilike.%" + user_search + "%,last_name.ilike.%" + user_search + "%,account_no.ilike.%" + user_search + "%,company_name.ilike.%" + user_search + "%,email_address.ilike.%" + user_search + "%";
            var { data, error } = await supabase.from('user_list').select('*').neq('status', '3').neq('role', '1').or(filter_values);
        } else {
            var { data, error } = await supabase.from('user_list').select('*').neq('status', '3').neq('role', '1');
        }
    } else {
        var { data, error } = await supabase.from('user_list').select('*').neq('status', '3').neq('role', '1');
    }
    if (error) {
        var resepnse_data = { "success": false, "msg": error.message }
        return (resepnse_data);
    } else {
        // console.log(data);
        var resepnse_data = { "success": true, "msg": "user_list", "data": data }
        return (resepnse_data);
    }
}

export default user_list_api;