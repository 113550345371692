import supabase from './supabaseconnect';

export async function logout_api() {

    var { error } = await supabase.auth.refreshSession();

    var { error } = await supabase.auth.signOut()

    if(error){
        var resepnse_data = {"success":false,"msg":error.message}
        return(resepnse_data);
    }else{
        var resepnse_data = {"success":true,"msg":"Logged out Successfully"}
        return(resepnse_data);
    }
}

export async function reset_cancel_api() {
    const { data: { user } } = await supabase.auth.getUser()
    // console.log(user.email);
    let { data1, error } = await supabase.from('user').update({ "pwd_set": 0}).eq('email_address', user.email);
    if (error) {
        var response_data = { "success": false, "msg": error.message }
        return (response_data);
    } else {
        let { error } = await supabase.auth.signOut()

        if(error){
            var resepnse_data = {"success":false,"msg":error.message}
            return(resepnse_data);
        }else{
            var resepnse_data = {"success":true,"msg":"Logged out Successfully"}
            return(resepnse_data);
        }
    }
}
