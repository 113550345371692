import './App.css';
import { Routes, Route } from "react-router-dom";
import Menu from "./pages/Menu";
import Login from "./pages/Login";
import Nopage from "./pages/Nopage";
import Register from "./pages/Register";
import PRIVACYPOLICY from './pages/privacy-policy';
import TERMSANDCONDITION from './pages/terms-Conditions';
import LEFTMENU from './pages/leftMenu';
import CLAIMS from './pages/claims';
import AddClaim from './pages/AddClaim.js';
import ResetPassword from './pages/ResetPassword';
import React from "react";
import { useState, useEffect } from 'react';
import supabase from "./api/supabaseconnect.js";
import Claimdetails from './pages/claimdetails';
import UsersList from './pages/usersList.js';
import AllCLAIMS from './pages/allClaims.js';
import CreatePassword from './pages/createPassword.js';
import NewTutorial from './pages/newtutorial.js';
import Profile from './pages/profile.js';
import Forgetpassword from './pages/forgetpassword.js';
import EditCheck from './pages/editclaim.js';
import Details from './pages/details.js';
import Home from './pages/home.js';
import Return from './pages/returns.js';
import AddReturns from './pages/addreturns.js';
import Upload from './pages/upload.js';
import Editreturns from './pages/editreturns.js';
import ReturnDetails from './pages/returndetails.js';
import ReturnProfile from './pages/returnprofile.js';
import UserDetails from './pages/userdetails.js';
import Returnlist from './pages/returnslist.js';
import Rmadetails from './pages/rmadetails.js';
import Claimedit from './pages/adminclaimedit.js';
import Rmaedit from './pages/rmaedit.js';


function App() {

  const [session, setSession] = React.useState(null)
  let [role, setRole] = useState(null);


  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {

      if (session != null) {
        setRole(role = session.user.user_metadata.role);
        // console.log('role',role);
      }

      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])




  return (

    <Routes>
      <Route path="/" element={<Menu />}>
        <Route index element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="home" element={<Home />} />
        <Route path="returns" element={<Return />} />
        <Route path="addreturns" element={<AddReturns />} />
        <Route path="*" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/privacy-policy" element={<PRIVACYPOLICY />} />
        <Route path="/terms-Conditions" element={<TERMSANDCONDITION />} />
        <Route path="leftMenu" element={<LEFTMENU />} />
        <Route path="claims" element={(role === 'admin') ? < Login /> : < CLAIMS />} />
        <Route path="addclaim" element={(role === 'admin') ? < Login /> : < AddClaim />} />
        <Route path="editclaim/:id" element={<EditCheck />} />
        <Route path="editreturns/:id" element={<Editreturns />} />
        <Route path="claimedit/:id" element={(role === 'admin') ? < Claimedit /> : < Login />} />
        <Route path="rmaedit/:id" element={(role === 'admin') ? < Rmaedit /> : < Login />} />
        <Route path="Claimdetails/:id" element={<Claimdetails />} />
        <Route path="returndetails/:id" element={<ReturnDetails />} />
        <Route path="createpassword" element={<CreatePassword />} />
        <Route path="newtutorial" element={<NewTutorial />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/userprofile' element={<ReturnProfile />} />
        <Route path='/upload' element={(role === 'admin') ? < Login /> : < Upload />} />
        <Route path="forgetpassword" element={<Forgetpassword />} />
        <Route path="details/:id" element={(role === 'admin') ? < Details /> : < Login />} />
        <Route path="userdetails/:id" element={(role === 'admin') ? < UserDetails /> : < Login />} />
        <Route path="rmadetails/:id" element={(role === 'admin') ? < Rmadetails /> : < Login />} />
        <Route path="userslist" element={(role === 'admin') ? < UsersList /> : < Login />} />
        <Route path="allclaims" element={(role === 'admin') ? < AllCLAIMS /> : < Login />} />
        <Route path="returnslist" element={(role === 'admin') ? < Returnlist /> : < Login />} />
      </Route>
    </Routes>

  );
}

export default App;
