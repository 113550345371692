import React from "react";
import export_btn from "../assets/export.png"
import add_claims_btn from "../assets/add_claim.png"
import filter_btn from "../assets/filter.png"
import '../pages/allclaims.css'
import search_icon from '../assets/search_icon.png'
import LEFTMENU from "./leftMenu";
import BackTOPBtn from '../assets/back-top-btn.png';
import NotifyIcon from '../assets/notification-icon.png';
// import claim_list_api from '../api/claim_list_api';
import admin_claims_list from "../api/admin_claim_list_api.js";
import { useEffect, useState } from "react";
import supabase from "../api/supabaseconnect.js";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import GIFLOADER from '../assets/loader.gif';
// import { DatePicker } from 'antd';
import moment from "moment";
import { useForm } from 'react-hook-form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import edit_icon from "../assets/editing.png"


// const { RangePicker } = DatePicker;

function AllCLAIMS() {



    var [suchidden, ShowSuc] = useState(true);
    var [errhidden, ShowErr] = useState(true);
    var [btndis, SetBtnDisable] = useState(false);


    const [claims, SetClaims] = React.useState([]);
    const [session, setSession] = React.useState(null)
    // console.log(claims);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setrecordsPerPage] = useState(5);

    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = claims.slice(firstIndex, lastIndex);
    const npage = Math.ceil(claims.length / recordsPerPage);
    const pnumbers = [...Array(npage + 1).keys()].slice(1);
    const [order, setOrder] = useState("ASC");
    const [searchValue, setSearchValue] = useState("")
    const [show, setShow] = useState(false);
    const [toggle, SetToggle] = useState(1);
    const [dates, SetDates] = useState([]);
    const isEmpty = claims.length === 0;
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [filterStatus, SetfilterStatus] = useState([{}])
    const [filterDate, SetfilterDate] = useState([]);
    const [items_per_page, setItems_per_page] = useState(0);
    const [filterSearch, SetfilterSearch] = useState('');

    const { register, watch, handleSubmit, formState: { errors }, reset, setValue } = useForm();

    const onError = (errors) => {
        console.log(errors)
    }
    const filterSubmit = (req_data) => {
        console.log(req_data)
        document.getElementById("divsection").style.backgroundColor = "#ffdc9b";

        localStorage.setItem('allclaim_page_start', 0)
        localStorage.setItem('allclaim_curr_page', 1)

        setItems_per_page(0)
        setCurrentPage(1)
        SetfilterStatus(req_data)
        SetfilterDate(dates);
        setShow(!show)
        SetBtnDisable(false);
        dates.push(startDate)
        dates.push(endDate)
        var response_data = admin_claims_list(req_data, dates, '', '', '').then(res => {
            SetClaims(res.data);
            SetBtnDisable(true);
            SetDates([])
            localStorage.setItem('allclmsfilterdata', JSON.stringify(req_data))
            if (dates != undefined && dates != '' && dates != null && dates[0] != undefined && dates[1] != undefined) {
                localStorage.setItem('allclmsfildate', dates)
            }
        })
    }
    function ClearFilter() {
        reset();
        setShow(!show)
        document.getElementById("divsection").style.backgroundColor = "white";
        SetBtnDisable(false);
        localStorage.removeItem('allclmsfilterdata');
        localStorage.removeItem('allclmsfildate');
        localStorage.setItem('allclaim_page_start', 0)
        localStorage.setItem('allclaim_curr_page', 1)

        // SetDates([])
        SetfilterStatus([{}]);
        SetfilterDate([]);
        setStartDate()
        setCurrentPage(1)
        setItems_per_page(0)
        setEndDate()
        var response_data = admin_claims_list('', '', '', '', '').then(res => {
            SetClaims(res.data);
            SetBtnDisable(true);
            // setStartDate(new Date())
        })
    }
    function updateToggle(id) {
        SetToggle(id);
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        // window.history.forward();

        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session)
        })
        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })
        var countfilteritm = JSON.parse(localStorage.getItem('allclmsfilterdata'));
        console.log(countfilteritm)
        if (countfilteritm != undefined && countfilteritm != '' && countfilteritm != null) {
            SetfilterStatus(countfilteritm)
            // setChecked(!checked)
        } else {
            countfilteritm = ''
        }
        if (countfilteritm && countfilteritm.allclaim === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('allclaim', countfilteritm.allclaim === true)
        }
        if (countfilteritm && countfilteritm.rejected === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('rejected', countfilteritm.rejected === true)

        }
        if (countfilteritm && countfilteritm.closed === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('closed', countfilteritm.closed === true)

        }
        if (countfilteritm && countfilteritm.entryhold === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('entryhold', countfilteritm.entryhold === true)

        }
        if (countfilteritm && countfilteritm.approved === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('approved', countfilteritm.approved === true)

        }
        if (countfilteritm && countfilteritm.submitted === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('submitted', countfilteritm.submitted === true)
        }
        if (countfilteritm && countfilteritm.draft === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('draft', countfilteritm.draft === true)
        }
        if (countfilteritm && countfilteritm.error === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('error', countfilteritm.error === true)
        }
        if (countfilteritm && countfilteritm.failed === true) {
            document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            setValue('failed', countfilteritm.failed === true)
        }
        var splitdate = [];
        var filterdate = (localStorage.getItem('allclmsfildate'));
        console.log(filterdate)
        if (filterdate != undefined && filterdate != '' && filterdate != null) {
            var splitdate = filterdate.split(',');
            SetfilterDate(splitdate)
            setStartDate(splitdate[0]);
            setEndDate(splitdate[1]);
            if (splitdate != []) {
                document.getElementById("divsection").style.backgroundColor = "#ffdc9b";
            }
        } else {
            filterdate = []
        }
        var response_data = admin_claims_list(countfilteritm, splitdate, '', '', '').then(res => {
            console.log(res.data);
            SetClaims(res.data);
            SetBtnDisable(true);
            var crtpage = localStorage.getItem('fromdetails')
            var countperpage = localStorage.getItem('currentpage');
            if (crtpage == 'pagecount' && countperpage != undefined && countperpage != '') {
                // setrecordsPerPage([])
                setrecordsPerPage(countperpage);
            }

            var claim_page_start = localStorage.getItem('allclaim_page_start');
            var claim_curr_page = localStorage.getItem('allclaim_curr_page');

            if (claim_page_start != undefined && claim_page_start != '' && claim_curr_page != undefined && claim_curr_page != '') {
                setItems_per_page(parseInt(claim_page_start));
                setCurrentPage(parseInt(claim_curr_page));
            }
        })

        return () => subscription.unsubscribe()
    }, [searchValue === ""])


    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
            var start_ind = (currentPage - 2) * recordsPerPage;
            setItems_per_page(start_ind)
            localStorage.setItem('allclaim_page_start', start_ind)
            localStorage.setItem('allclaim_curr_page', (currentPage - 1))
        }
    }
    // currentPage
    function changeCurPage(id) {
        // console.log(id)
        var start_ind = (id - 1) * recordsPerPage;
        setItems_per_page(start_ind)
        setCurrentPage(id)
    }
    // nextPage
    function nextPage() {
        if (currentPage !== npage) {
            var start_ind = currentPage * recordsPerPage;
            setItems_per_page(start_ind)
            setCurrentPage(currentPage + 1)
            localStorage.setItem('allclaim_page_start', start_ind)
            localStorage.setItem('allclaim_curr_page', (currentPage + 1))
        }
    }
    const sorting = (col) => {
        setCurrentPage(1);
        setItems_per_page(0)
        localStorage.setItem('allclaim_page_start', 0)
        localStorage.setItem('allclaim_curr_page', 1)

        if (order === 'ASC') {
            var response_data = admin_claims_list(filterStatus, filterDate, col, order, filterSearch).then(res => {
                SetClaims(res.data)
            })
            setOrder('DSC');
        }
        if (order === 'DSC') {
            var response_data = admin_claims_list(filterStatus, filterDate, col, order, filterSearch).then(res => {
                SetClaims(res.data);
                SetBtnDisable(true)
            })
            setOrder('ASC');
        }
    }
    function pageChange(evt) {
        setCurrentPage(1);
        setItems_per_page(0)
        localStorage.setItem('allclaim_page_start', 0)
        localStorage.setItem('allclaim_curr_page', 1)

        setrecordsPerPage(evt.target.value);
        localStorage.setItem('currentpage', evt.target.value)
    }
    // function searchResults(searchResult) {
    //     console.log(searchResult);
    //     SetfilterSearch(searchResult)
    //     setCurrentPage(1);
    //     setItems_per_page(0)
    //     var response_data = admin_claims_list(filterStatus, filterDate, '', '', searchResult).then(res => {
    //         SetClaims(res.data)
    //     })
    // }
    var search_res = watch('user_search')
    useEffect(() => {
        // SetfilterSearch(searchResult)
        setCurrentPage(1);
        setItems_per_page(0)
        // console.log(result.target.value);
        const delayApiCall = setTimeout(() => {
            var response_data = admin_claims_list(filterStatus, filterDate, '', '', search_res).then(res => {
                SetClaims(res.data)
            })
        }, 1000);
        return () => clearTimeout(delayApiCall);
    }, [search_res])
    // const claimList = async () => {
    // var response_data = claim_list_api();
    // console.log(response_data);
    // SetBtnDisable(btndis = false);

    // if (response_data.success) {
    //     SetSuMsg(suMSG = response_data.msg);
    //     ShowSuc(!suchidden);
    //     setTimeout(() => {
    //         ShowSuc(suchidden = true);
    //     }, 3000);
    //     event.target.reset();
    //     SetBtnDisable(btndis = true);
    // } else {
    //     SetErMsg(suMSG = response_data.msg);
    //     ShowErr(!errhidden);
    //     setTimeout(() => {
    //         ShowErr(errhidden = true);
    //     }, 3000);
    //     SetBtnDisable(btndis = true);
    // }
    // }



    // console.log(response_data);
    // SetBtnDisable(btndis = false);

    // if (response_data.success) {
    //     SetSuMsg(suMSG = response_data.msg);
    //     ShowSuc(!suchidden);
    //     setTimeout(() => {
    //         ShowSuc(suchidden = true);
    //     }, 3000);
    //     event.target.reset();
    //     SetBtnDisable(btndis = true);
    // } else {
    //     SetErMsg(suMSG = response_data.msg);
    //     ShowErr(!errhidden);
    //     setTimeout(() => {
    //         ShowErr(errhidden = true);
    //     }, 3000);
    //     SetBtnDisable(btndis = true);
    // }
    // }

    // claim_list_api()

    function details_page(res, status) {
        console.log(status);
        // return navigate(`/claimDetails/${res}`);
        if (status == "0") {
            return navigate(`/editclaim/${res}`);
        } else {
            return navigate(`/details/${res}`);

        }
    }
    function editPage(evt) {
        return navigate(`/claimedit/${evt}`);

    }
    return (
        <div className=" sideMenu">

            <LEFTMENU />
            <div className="container details_All">
                <div hidden={btndis} className='loader'>
                    <img className='max-loader' src={GIFLOADER} alt="Maxon Loader" />
                </div>
                <div className="claim_top_bar">
                    <div className=" user_topbar">
                        <div className="">
                            <h3>
                            Warranty Claims</h3>
                        </div>
                        <div className=" userSearch">
                            <div className="control_search">
                                <form className="search-area">
                                    <img src={search_icon} alt="search-icon" />
                                    {/* <input type="text"
                                        placeholder="Search..." onChange={(e) => searchResults(e.target.value)} /> */}
                                    <input type="text"
                                        placeholder="Search..."{...register('user_search')} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="claim_top_btns">
                    {/* <div className="control_search mob_ser">
                        <form className="search-area">
                            <img src={search_icon} alt="search-icon" />
                            <input type="text"
                                placeholder="Search..." {...register('user_search')} />
                        </form>
                    </div> */}
                    <div> </div>
                    <div className="claimBtns">
                        <button className="filter_btnn" onClick={() => setShow(!show)} id="divsection"> <img src={filter_btn}></img> Filter</button>
                    </div>
                </div>
                <div className="filter_popTotal">
                    {
                        show && <div className="claimPop filres">
                            <div className="claim-popTotal">
                                <div className="popSubclass">
                                    <div className="claim-pop_left">
                                        <ul>
                                            <li onClick={() => updateToggle(1)}>Status</li>
                                            <li onClick={() => updateToggle(2)}>Date</li>
                                        </ul>
                                    </div>
                                    <div className="claim-pop_right">
                                        <form onSubmit={handleSubmit(filterSubmit, onError)}>
                                            <div className={toggle === 1 ? "show-content" : "content"}>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" id="draft"  {...register("draft")} />
                                                    <label htmlFor="draft" className="form-check-label" >
                                                        Draft
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="submitted" {...register("submitted")} />
                                                    <label htmlFor="submitted" className="form-check-label" >
                                                        Submitted
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="approved" {...register("approved")} />
                                                    <label htmlFor="approved" className="form-check-label" >
                                                        Approved
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="entryhold" {...register("entryhold")} />
                                                    <label htmlFor="entryhold" className="form-check-label" >
                                                        Entry Hold
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="closed" {...register("closed")} />
                                                    <label htmlFor="closed" className="form-check-label" >
                                                        Closed
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="rejected" {...register("rejected")} />
                                                    <label htmlFor="rejected" className="form-check-label" >
                                                        Rejected
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="error" {...register("error")} />
                                                    <label htmlFor="error" className="form-check-label" >
                                                        Error
                                                    </label>
                                                </div>
                                                <div className="form-check filt_checkbox">
                                                    <input className="form-check-input" type="checkbox" value="" id="failed" {...register("failed")} />
                                                    <label htmlFor="failed" className="form-check-label" >
                                                        Failed
                                                    </label>
                                                </div>
                                            </div>
                                            <div className={toggle === 2 ? "show-content" : "content"}>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={onChange}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    selectsRange
                                                    inline
                                                    maxDate={new Date()}

                                                />
                                            </div>
                                            <div className="filter_btns">
                                                <button className="clr_btn" onClick={ClearFilter}>Clear</button>
                                                <button className="cancal-sub-btn can_btnClm" onClick={() => setShow(!show)}>Cancel </button>
                                                <button type="submit" className="addclaim-sub-btn" >Apply </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                </div>
                <div className="claims_table table-responsive">
                    {isEmpty ? (
                        <p className="display_text">No claims to show</p>
                    ) : (
                        <table className="table desc_table">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col" onClick={() => sorting('web_reference_no')}>
                                        <b>Claim ID</b> <i className="fa fa-fw fa-sort"></i></th>
                                    <th scope="col" >
                                        <b>Invoice Number</b> </th>
                                    <th scope="col" onClick={() => sorting('model_no')}>
                                        <b>Lift Gate Model</b> <i className="fa fa-fw fa-sort"></i></th>
                                    <th scope="col" onClick={() => sorting('serial_no')}>
                                        <b>Gate Serial #</b> <i className="fa fa-fw fa-sort"></i></th>
                                    <th scope="col" onClick={() => sorting('web_entry_date')}>
                                        <b>Claim Date</b> <i className="fa fa-fw fa-sort"></i></th>
                                    <th scope="col" onClick={() => sorting('status_value')}>
                                        <b>Status</b><i className="fa fa-fw fa-sort"></i> </th>
                                    <th scope="col">Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {records?.map((res, index) =>
                                    <tr key={index} data-id={res.id} >
                                        <React.Fragment >
                                            <td onClick={() => details_page(res.id, res.status)}>{(items_per_page + index + 1)}</td>
                                            <td onClick={() => details_page(res.id, res.status)}> {res.web_reference_no}</td>
                                            <td onClick={() => details_page(res.id, res.status)}>
                                                {(() => {
                                                    if (res.invoice_no == '') {
                                                        return '-';
                                                    } else {
                                                        return res.invoice_no;
                                                    }
                                                })()}
                                            </td>
                                            <td className="lift_model" onClick={() => details_page(res.id, res.status)}>{res.model_no}</td>
                                            <td onClick={() => details_page(res.id, res.status)}> {res.serial_no}</td>
                                            <td onClick={() => details_page(res.id, res.status)}>{moment(res.web_entry_date).format("MM-DD-YYYY")} </td>
                                            <td onClick={() => details_page(res.id, res.status)}>
                                            {(() => {
                                                    if (res.status_value === 'Draft') {
                                                        return <p className="draft_color">Draft</p>;
                                                    } else if (res.status_value === "Submitted") {
                                                        return <p className="sub_color">Submitted</p>;
                                                    } else if (res.status_value === "Rejected") {
                                                        return <p className="del_color">Rejected</p>;
                                                    } else if (res.status_value === "Open") {
                                                        return <p className="draft_color">Open</p>;
                                                    } else if (res.status_value === "Closed") {
                                                        return <p className="sub_color">Closed</p>;
                                                    } else if (res.status_value === "Approved") {
                                                        return <p className="sub_color">Approved</p>;
                                                    } else if (res.status_value === "Failed") {
                                                        return <p className="del_color">Failed</p>;
                                                    } else if (res.status_value === "Error") {
                                                        return <div className="act_img">
                                                            <p className="del_color">Error</p>
                                                        </div>
                                                    } else {
                                                        return <p className="">{res.status_value}</p>
                                                    }
                                                })()}
                                            </td>
                                        </React.Fragment>
                                        <td >
                                            {(() => {
                                                if (res.status === 0) {
                                                    return <img src={edit_icon} onClick={() => editPage(res.id)} />

                                                } else {
                                                    return <p onClick={() => details_page(res.id, res.status)}>View</p>
                                                }
                                            })()}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                    <div className="mob_card">
                        <div className="card_data">
                            {records?.map((res, index) =>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="Card_top">
                                            <div>
                                            {(() => {
                                                    if (res.status_value === "Draft") {
                                                        return <p className="draft_color">
                                                            <ul> <li>
                                                                Draft
                                                            </li></ul>
                                                        </p>;
                                                    } else if (res.status_value === "Submitted") {
                                                        return <p className="sub_color">
                                                            <ul><li>
                                                                Submitted
                                                            </li></ul>
                                                        </p>;
                                                    } else if (res.status_value === "Rejected") {
                                                        return <p className="del_color">
                                                            <ul className="del_color"><li>
                                                                Rejected
                                                            </li></ul>
                                                        </p>;
                                                    } else if (res.status_value === "Open") {
                                                        return <p className="draft_color">
                                                            <ul> <li>
                                                                Open
                                                            </li></ul>
                                                        </p>;
                                                    } else if (res.status_value === "Closed") {
                                                        return <p className="sub_color">
                                                            <ul><li>
                                                                Closed
                                                            </li></ul>
                                                        </p>;
                                                    } else if (res.status_value === "Approved") {
                                                        return <p className="sub_color">
                                                            <ul><li>
                                                                Approved
                                                            </li></ul>
                                                        </p>;
                                                    } else if (res.status_value === "Failed") {
                                                        return <p className="del_color">
                                                            <ul><li>
                                                                Failed
                                                            </li></ul>
                                                        </p>;
                                                    } else if (res.status_value === "Error") {
                                                        return <p className="del_color">
                                                            <ul><li>
                                                                Error
                                                            </li></ul>
                                                        </p>;
                                                    } else {
                                                        return <p>{res.status_value}</p>
                                                    }
                                                })()}
                                            </div>
                                            <div className="edit_icon_card">
                                                {(() => {
                                                    if (res.status === 0) {
                                                        return <img src={edit_icon} onClick={() => editPage(res.id)} />

                                                    } else {
                                                        return <span onClick={() => details_page(res.id, res.status)}>View</span>
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                        <div className="row card_top"  >
                                            <div className="col-sm">
                                                <div className="mob_data_lable">
                                                    <label>Claim Id</label>
                                                    {res.web_reference_no}
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="mob_data_lable">
                                                    <label>Claim Date</label>
                                                    {moment(res.web_entry_date).format("MM-DD-YYYY")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row card_top">
                                            <div className="col-sm">
                                                <div className="mob_data_lable">
                                                    <label>Gate Serial #</label>
                                                    {res.serial_no}
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <div className="mob_data_lable">
                                                    <label>Invoice Number</label>
                                                    {res.invoice_no}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row card_top">
                                            <div className="col">
                                                <div className="mob_data_lable">
                                                    <label>Lift Gate Model</label>
                                                    {res.model_no}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="claim_pagination allclaims">
                        <div className="itemsPage">
                            <p>Items Per Page:</p>
                            <select value={recordsPerPage} className="form-select" onChange={(e) => pageChange(e)}>
                                <option >5</option>
                                <option>10</option>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </div>
                        <div className="page_wid">
                            <span>Claims({claims.length})</span>
                            <nav >
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a className="page-link"
                                            onClick={prePage}>Prev</a>
                                    </li>
                                    {/* {
                                        pnumbers.map((n, i) => (
                                            <div className="card_pagination">
                                                <li className={`page-item ${currentPage === n ? 'active' : ""}`} key={i}>
                                                    <a className="page-link"
                                                        onClick={() => changeCurPage(n)}>{n}</a>
                                                </li>
                                            </div>
                                        ))
                                    } */}
                                    <li className="page-item">
                                        <a className="page-link"
                                            onClick={nextPage}>Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllCLAIMS;