import supabase from './supabaseconnect';

async function login_api(email_id,password) {
    var { data, error } =await supabase.from('user').select('*').eq('email_address',email_id).order('created_at', { ascending: false });

    if(error){
        if(error.message == 'TypeError: Failed to fetch'){
            error.message = 'Login failed. Check your internet connection.'
        }
        var resepnse_data = {"success":false,"msg":error.message}
        return(resepnse_data);
    }else{
        if(data.length > 0){
            var user_name = data[0].first_name + " " + data[0].last_name;
            var account_no = data[0].account_no;
            if(data[0].status == 1){
                var pwd_set = 0;
                if(data[0].pwd_set == 1){
                    pwd_set = 1;
                    var { data, error } = await supabase.auth.verifyOtp({
                        email: email_id,
                        token: password,
                        type: 'email'
                    });                    
                }else{
                    var { data, error } = await supabase.auth.signInWithPassword({
                        email: email_id,
                        password: password
                    });
                }
                if(error){
                    var resepnse_data = {"success":false,"msg":error.message}
                    return(resepnse_data);
                }else{
                    var resepnse_data = {"success":true,"msg":"Loggedin Successfully","data":data,"pwd_set":pwd_set,"user_name":user_name,"maxon_account_no":account_no}
                    return(resepnse_data);
                }
            }else if(data[0].status == 0){
                var resepnse_data = {"success":false,"msg":"Account not yet approved"}
                return(resepnse_data);
            }else if(data[0].status == 2){
                var resepnse_data = {"success":false,"msg":"Your account has been rejected"}
                return(resepnse_data);
            }else if(data[0].status == 3){
                var resepnse_data = {"success":false,"msg":"Your account has been removed"}
                return(resepnse_data);
            }
        }
        else{
            var resepnse_data = {"success":false,"msg":"User not exists"}
            return(resepnse_data);
        }
    }
}

export default login_api;