import supabase from './supabaseconnect';
import emailjs from '@emailjs/browser';

async function register_api(maxon_account_no,first_name,last_name,email_id,company_name) {

    let { data, error } =await supabase.from('user').select('*').eq('email_address',email_id).neq('status','3').order('created_at', { ascending: false });

    if(error){
        var resepnse_data = {"success":false,"msg":error.message}
        return(resepnse_data);
    }else{
        console.log(data);
        if(data.length > 0){
            if(data[0].status == 1){
                var resepnse_data = {"success":false,"msg":"User Already Exist"}
                return(resepnse_data);
            }else{
                var resepnse_data = {"success":false,"msg":"User Already Exist.Admin not yet approved"}
                return(resepnse_data);
            }
        }
        else
        {
            // var AccVal =await supabase.from('user').select('*').eq('account_no',maxon_account_no).neq('status','3');

            // if(AccVal.error){
            //     var resepnse_data = {"success":false,"msg":error.message}
            //     return(resepnse_data);
            // }else{

            //     if(AccVal.data.length > 0){
            //         var resepnse_data = {"success":false,"msg":"Account No Already Registered."}
            //         return(resepnse_data);
            //     }else{

                    var values = [
                        { first_name: first_name, last_name: last_name, email_address: email_id, account_no: maxon_account_no,company_name: company_name }
                    ];
                    let { data, error } =await supabase.from('user').insert(values);

                    if(error){
                        var resepnse_data = {"success":false,"msg":error.message}
                        return(resepnse_data);
                    }else
                    {
                        // const PUBLIC_Key= '5NGpf_PYetS9CEWBi'
                        // const SERVICE_ID = 'service_l0jwugw';
                        // const user_TEMPLATE_ID = 'template_mgr8zqs';
                        // const admin_TEMPLATE_ID = 'template_8tuayuc';
                        // var user_name = first_name + ' ' + last_name
                        // const welcometemplateParams = {
                        //     email_address : email_id,
                        //     user_name : user_name
                        // };

                        // const newusernotifytemplateParams = {
                        //     first_name : first_name,
                        //     last_name : last_name,
                        //     user_email_id : email_id,
                        //     account_no : maxon_account_no,
                        //     company_name : company_name,
                        //     to_email_address : 'innoadmaxon@gmail.com'
                        // };

                        // emailjs.send(SERVICE_ID, user_TEMPLATE_ID, welcometemplateParams, PUBLIC_Key).then(
                        //     (response) => {
                        //       console.log('SUCCESS!', response.status, response.text);
                        //     },
                        //     (error) => {
                        //       console.log('FAILED...', error);
                        //     },
                        //   );

                        //   emailjs.send(SERVICE_ID, admin_TEMPLATE_ID, newusernotifytemplateParams, PUBLIC_Key).then(
                        //     (response) => {
                        //       console.log('SUCCESS!', response.status, response.text);
                        //     },
                        //     (error) => {
                        //       console.log('FAILED...', error);
                        //     },
                        //   );
                        var resepnse_data = {"success":true,"msg":"Account Requested Successfully"}
                        return(resepnse_data);
                    }
                }
            // }
        // }
    }
}

export default register_api;