import supabase from './supabaseconnect';

async function resetpassword_api(password) {
    let { data, error } = await supabase.auth.updateUser({
        password: password
    });
    if(error){
        var resepnse_data = {"success":false,"msg":error.message}
        return(resepnse_data);
    }else{
        const { data: { user } } = await supabase.auth.getUser()
        console.log(user.email);
        let { data1, error } = await supabase.from('user').update({ "pwd_set": 0}).eq('email_address', user.email);
        if (error) {
            var response_data = { "success": false, "msg": error.message }
            return (response_data);
        } else {
            let { error } = await supabase.auth.signOut()

            if(error){
                var resepnse_data = {"success":false,"msg":error.message}
                return(resepnse_data);
            }else{        
                var resepnse_data = {"success":true,"msg":"Password Reset Successfully"}
                return(resepnse_data);
            }
        }
    }
}

export default resetpassword_api;