import React from "react";
import Menu from './Menu';
import Logo from '../assets/Logo.png';
import "../pages/home.css";
import { Link } from "react-router-dom";
import warranty from "../assets/warranty.png";
import returns from "../assets/return.png";
import { useState, useEffect } from "react";
import logout_icon from '../assets/logout.png';
import { useNavigate } from "react-router-dom";
import { logout_api } from '../api/logout_api.js';
import { profile_api } from "../api/profile_api.js";
import newlogout from '../assets/newlogout.svg';

function Home() {
    const navigate = useNavigate();

    const [activeyear, setActiveYear] = useState();
    var [suchidden, ShowSuc] = useState(true);
    var [errhidden, ShowErr] = useState(true);
    var [suMSG, SetSuMsg] = useState('');
    var [erMSG, SetErMsg] = useState('');
    var [btndis, SetBtnDisable] = useState(true);
    const logoutUser = async () => {
        var response_data = await logout_api();
        // console.log(response_data);
        navigate("/");
        SetBtnDisable(btndis = false);
        if (response_data.success) {
            SetSuMsg(suMSG = response_data.msg);
            ShowSuc(!suchidden);
            setTimeout(() => {
                localStorage.clear();
                ShowSuc(suchidden = true);

            }, 3000);
            SetBtnDisable(btndis = true);
        } else {
            SetErMsg(suMSG = response_data.msg);
            ShowErr(!errhidden);
            setTimeout(() => {
                ShowErr(errhidden = true);
            }, 3000);
            SetBtnDisable(btndis = true);
        }
    }

    const [pwd_set, setPwdSet] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
        const dateObj = new Date();
        const year = dateObj.getFullYear();
        setActiveYear(year)

        var response_data = profile_api().then(res => {
            if (res.success) {
                var userName = res.data[0].first_name + ' ' + res.data[0].last_name;
                setPwdSet(res.data[0].pwd_set);
            }
        })
    }, [])

    // function gotorma() {
    //     localStorage.setItem('homestatus', 'rma')
    // }
    // function gotowarranty() {
    //     localStorage.setItem('homestatus', 'warranty')
    // }
    // if (pwd_set == 0) {
    return (

        <div className="container-bg">
            <div className='home_top'>
                <img className='' src={Logo} alt="Maxon logo" />
                <div className="home_log" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <img src={newlogout}></img>
                    <div className="contentName home_p" >
                        <p><a style={{ color: '#ffffff' }} >LOG OUT</a></p>
                    </div>
                </div>
            </div>
            <div className="modal " id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog user_logout">
                    <div className="modal-content home_logout_pop">
                        <div className="modal-header">
                            <h5 className="modal-title log_title" id="exampleModalLabel">Confirmation</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            Are you sure you want to logout?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary no_btn" data-bs-dismiss="modal">No</button>
                            <button type="button" className="btn logyesbtn" data-bs-dismiss="modal" onClick={logoutUser}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home_main">
                <div className="home_top_content">
                    <div className="home_bold">
                        <p>Welcome to Maxon’s Warranty & RMA Portal</p>
                    </div>
                    <div className="small_top_content">
                        <p>This site provides comprehensive Warranty and RMA support. Please proceed by selecting from the option menus below.</p>
                    </div>
                    <div className="small_top_content">
                        <p>If you have any questions about the Warranty or RMA claims process, feel free to contact us at <span>1-800-227-4116</span> .</p>
                    </div>
                </div>
                <div className="btns_home">
                    <div className="Max_box" >
                        <Link to={"/claims"}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="home_img">
                                        <img src={warranty}></img>
                                    </div>
                                    <div>
                                        <p> Warranty Claim</p>
                                    </div>
                                    <div className="view_btn">
                                        <button>View</button>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="Max_box" >
                        <Link to={"/returns"}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="home_img">
                                        <img src={returns}></img>
                                    </div>
                                    <div>
                                        <p> RMA</p>
                                    </div>
                                    <div className="view_btn">
                                        <button>View</button>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="home_footer">
                    <div className="footer_copy">
                        <p>Copyright &copy; {activeyear} Maxon Lift Crop. All rights reserved</p>
                    </div>
                    <div className="home_content">
                        <div className="clmuser"  >
                            <a target="_blank" rel='noopener noreferrer' href="https://www.maxonlift.com/web-warranty-rma-tutorial"><span>User Tutorial</span></a>
                        </div>
                        <Link to={'/privacy-policy'}>
                            Policy Privacy</Link>
                        <Link to={'/terms-Conditions'}>
                            Terms & Conditions</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Home;